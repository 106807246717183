import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import * as yup from "yup";
import SocialLinks from "../../other/SocialLinks/SocialLinks";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

// function to convert date "May 29, 2023" to "Mon May 29"
const convertDate = (date) => {
  const d = new Date(date);
  const day = d.toLocaleString("default", { weekday: "short" });
  const month = d.toLocaleString("default", { month: "short" });
  const dayOfMonth = d.getDate();
  return `${day} ${month} ${dayOfMonth}`;
};

const images = [
  "sip_stroll_0001.jpg",
  "beach_volleyball_0002.jpg",
  "frisbee_0003.jpg",
  "silent_disco_ladies_0001.jpg",
];

const totalSteps = 9;

const EVENT_API_URL = `https://4cljs7mcdi.execute-api.us-east-1.amazonaws.com/default/socialevents`;

export default function Chat() {
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState("");
  const [formEnabled, setFormEnabled] = useState(true);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sex, setSex] = useState("male");
  const [instagram, setInstagram] = useState("");
  const [message, setMessage] = useState("");
  const [formType, setFormType] = useState("activeBeachesGroupChat");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const [veryInterestedInEvents, setVeryInterestedInEvents] = useState("");
  const [alsoVeryInterestedInEvents, setAlsoVeryInterestedInEvents] =
    useState("");
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [showFormError, setShowFormError] = useState(false);
  const [agreeAgreements, setAgreeAgreements] = useState(true);
  const [agreementsMustBeChecked, setAgreementsMustBeChecked] = useState(false);
  const [shirtSize, setShirtSize] = useState("M");
  const [env, setEnv] = useState("production");
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [showSpinner, setShowSpinner] = useState(true);

  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [event, setEvent] = useState({});

  const { eventId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (progressBarValue < 100) {
        setProgressBarValue(progressBarValue + 1);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [progressBarValue]);

  useEffect(() => {
    async function fetchData() {
      // create a mock function that will return a list of events after a delay
      let useMocks = false;
      if (!useMocks) {
        if (eventId) {
          // get events from this api - specific event
          await fetch(`${EVENT_API_URL}?id=${eventId}`)
            .then((res) => res.json())
            .then((data) => {
              console.log("specific event data: ", data);
              if (data && data.socialEvents && data.socialEvents.Item) {
                console.log("setting event: ", data.socialEvents.Item);
                setEvent(data.socialEvents.Item);
              } else {
              }
            })
            .catch((err) => {
              console.log("err", err);
              return [];
            });
        }
        // get events from this api - all public events
        await fetch(EVENT_API_URL)
          .then((res) => res.json())
          .then((data) => {
            console.log("public events data: ", data);
            if (data && data.socialEvents) {
              // sort events by date
              data.socialEvents.sort((a, b) => {
                return new Date(a.date) - new Date(b.date);
              });
              setEvents(data.socialEvents);

              // set current events to only include events that have not passed
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              const currentEvents = data.socialEvents.filter((eventItem) => {
                // if date is before yesterday, don't show it
                const eventDate = new Date(eventItem.date);
                if (eventDate < yesterday) {
                  return false;
                }
                return true;
              });
              setCurrentEvents(currentEvents);
            } else {
              console.log(
                "called public event api, but no events were returned"
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
            return [];
          });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const getInterestedEventTypes = () => {
    const interestedEventTypes = [
      "volleyball",
      "pickleball",
      "basketball",
      "dance",
      "yoga",
      "hiking",
      "biking",
      "skating",
      "running",
      "kayaking",
      "frisbee",
      "learning new languages",
      "learning new skills",
      "game nights",
      "beach hangouts",
      "paddle boarding",
    ];
    // format the types into an array of checkboxes like in upcomingEvents()
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap", // Allows items to wrap into the next line
          justifyContent: "space-between", // Adjust this to change how items are spaced
        }}
      >
        {interestedEventTypes.map((type, i) => {
          return (
            <div
              key={i}
              className="event-container"
              style={{
                width: "48%", // Adjust this to change how many items are in a row
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                border: "1px solid #e6e6e6",
                backgroundColor: "#f9f9f9",
                borderRadius: "15px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "10px",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  id={`eventCheckbox_${i}`}
                  className="event-checkbox"
                  style={{
                    marginRight: "10px",
                    transform: "scale(2.0)", // Increase checkbox size
                  }}
                  // onchange will just be a function that adds the type to the veryInterestedInEvents state
                  onChange={(e) => {
                    if (e.target.checked) {
                      setVeryInterestedInEvents(
                        veryInterestedInEvents + ", " + type
                      );
                    } else {
                      setVeryInterestedInEvents(
                        veryInterestedInEvents.replace(", " + type, "")
                      );
                    }
                  }}
                />
              </div>
              <div className="event-details">
                <small
                  className="font-weight-bold"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {type}
                </small>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const convertDateFromSocialEventId = (socialEventId) => {
    const [year, month, day] = socialEventId.split("-").slice(0, 3);
    // Create a date using local time
    const date = new Date(year, month - 1, day); // month is 0-indexed in JavaScript
    const dayName = date.toLocaleString("default", { weekday: "short" });
    const monthName = date.toLocaleString("default", { month: "short" });
    const dayOfMonth = date.getDate();
    return `${dayName} ${monthName} ${dayOfMonth}`;
  };

  const sortEvents = (events) => {
    return events.sort((a, b) => {
      const dateA = new Date(a.socialEventId.split("-").slice(0, 3).join("-"));
      const dateB = new Date(b.socialEventId.split("-").slice(0, 3).join("-"));

      if (dateA - dateB !== 0) {
        return dateA - dateB;
      }

      // If dates are the same, compare times
      const timeA = a.time ? a.time.split(":").map(Number) : [0, 0];
      const timeB = b.time ? b.time.split(":").map(Number) : [0, 0];

      return timeA[0] - timeB[0] || timeA[1] - timeB[1];
    });
  };

  const upcomingEvents = () => {
    const sortedEvents = sortEvents(currentEvents);

    return (
      <>
        <div style={{ paddingBottom: "20px" }}>
          {sortedEvents.length > 0 ? (
            <>
              {/* display each element in events array in a compact bootstrap card */}
              {sortedEvents.slice(0, 8).map((eventItem, i) => {
                // if date is before yesterday, don't show it
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const eventDate = new Date(
                  eventItem.socialEventId.split("-").slice(0, 3).join("-")
                );
                if (eventDate < yesterday) {
                  return null;
                }

                // Remove non-alphanumeric characters except spaces, apostrophes, dashes, dollar signs, parenthesis, and plus signs
                const cleanTitle = eventItem.title.replace(
                  /[^a-zA-Z0-9 \-\'\$\(\)\+]/g,
                  ""
                );

                return (
                  <div
                    key={i}
                    className="event-container"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      border: "1px solid #e6e6e6",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "15px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <input
                        type="checkbox"
                        id={`eventCheckbox_${i}`}
                        className="event-checkbox"
                        style={{
                          marginRight: "10px",
                          transform: "scale(2.0)", // Increase checkbox size
                        }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedEvents([
                              ...selectedEvents,
                              {
                                title: cleanTitle,
                                date: convertDateFromSocialEventId(
                                  eventItem.socialEventId
                                ),
                                time: eventItem.time,
                                socialEventId: eventItem.socialEventId,
                              },
                            ]);
                          } else {
                            setSelectedEvents(
                              selectedEvents.filter(
                                (item) =>
                                  item.socialEventId != eventItem.socialEventId
                              )
                            );
                          }
                          console.log("selectedEvents", selectedEvents);
                        }}
                      />
                    </div>
                    <div className="event-details">
                      <small
                        className="font-weight-bold"
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {cleanTitle}
                      </small>
                      <br />
                      <small
                        className="text-muted"
                        style={{ fontSize: "12px" }}
                      >
                        {convertDateFromSocialEventId(eventItem.socialEventId)}{" "}
                        @ {eventItem.time}
                      </small>
                    </div>
                  </div>
                );
              })}
            </>
          ) : showSpinner ? (
            <div className="text-center">
              <i className="fa fa-spinner fa-spin fa-3x"></i>
            </div>
          ) : (
            <div className="text-center">
              <p>
                No events available. Please click the next button to proceed.
              </p>
            </div>
          )}
        </div>
      </>
    );
  };

  const onFormSubmit = (e) => {
    setFormError("");

    // validate form against yup schema
    const schema = yup.object().shape({
      email: yup.string().max(100).email().required(),
      formType: yup.string().max(100).required(),
      firstName: yup.string().max(100).optional(),
      lastName: yup.string().max(100).optional(),
      message: yup.string().max(1000).optional(),
      sex: yup.string().max(6).optional(),
      phone: yup.string().optional(),
      instagram: yup.string().max(100).optional(),
      veryInterestedInEvents: yup.string().max(1000).optional(),
      selectedEvents: yup.array().optional(),
      shirtSize: yup.string().max(6).optional(),
    });

    // if message length is < 2 just add some default text there
    let messageHolder = null;
    if (!message || message.length < 2) {
      console.log("form submitted, changing message to default");
      messageHolder = "default";
    } else {
      messageHolder = message;
    }

    schema
      .validate({
        email,
        formType,
        firstName,
        lastName,
        sex,
        message: messageHolder,
        phone,
        instagram,
        veryInterestedInEvents:
          veryInterestedInEvents.trim() +
          ", " +
          alsoVeryInterestedInEvents.trim(),
        selectedEvents,
        shirtSize,
      })
      .then(() => {
        // build the request
        const req = {
          email,
          formType,
          firstName,
          lastName,
          // message: messageHolder,
          phone,
          photo: "default",
          referredBy: messageHolder,
          zsex: sex,
          socials: "instagram: " + instagram,
          reInterestedInEvents:
            veryInterestedInEvents.trim() +
            ", " +
            alsoVeryInterestedInEvents.trim(),
          selectedEvents,
          formType: "formSocialGroupChat",
          shirtsize: shirtSize,
        };

        // send the request to the server
        // local endpoint
        const formApi = {
          local: "http://localhost:3001/formWaitingListAgentPurchases",
          local2:
            "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
          production:
            "https://jf0dzeinoa.execute-api.us-east-1.amazonaws.com/prod/socialchatform",
        };

        fetch(formApi[env], {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(req),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log("data", data);
            setFormEnabled(false);
            setProgressBarValue(100);

            if (data.error) {
              setFormError(JSON.stringify(data.error));
            } else {
              setFormSuccess(true);
              navigate("/subscribe");
            }
          })
          .catch((err) => {
            console.log("err", err);
          });

        setFormEnabled(false);
        setProgressBarValue(100);
        setFormSuccess(true);
        navigate("/subscribe");
      })
      .catch((err) => {
        console.log("err", err);
        setFormError("All fields are required unless specified");
        setFormEnabled(true);
      });
  };

  const earlyFormSubmit = (req) => {
    // add whatever fields we have to the req object
    req.formType = "formSocialGroupChat";

    console.log("req", req);

    // Send the request to the server
    const formApi = {
      local: "http://localhost:3001/formWaitingListAgentPurchases",
      local2:
        "https://ps5lyq6sa8.execute-api.us-east-1.amazonaws.com/default/formWaitingListAgentPurchases",
      production:
        "https://jf0dzeinoa.execute-api.us-east-1.amazonaws.com/prod/socialchatform",
    };

    // change req.message key to be referredBy
    req.referredBy = req.message;
    delete req.message;

    // change req.sex key to be zsex
    req.zsex = req.sex;
    delete req.sex;

    // change req.veryInterestedInEvents key to be reInterestedInEvents
    req.reInterestedInEvents = req.veryInterestedInEvents;
    delete req.veryInterestedInEvents;

    fetch(formApi[env], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(req),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("early submission data", data);
        if (data.error) {
          console.log(
            "early form submissiondata error",
            JSON.stringify(data.error)
          );
        } else {
          // Optionally handle success, e.g., set a flag or log
          console.log("Early form submission successful");
        }
      })
      .catch((err) => {
        console.log("Error in early form submission", err);
        console.log("Error early submitting form");
      });
  };

  const nextStep = () => {
    if (validateStep()) {
      setError("");
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, 0);
    } else {
      setError("Please fill out all required fields.");
    }
  };

  const prevStep = () => {
    setError("");
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const validateStep = () => {
    switch (currentStep) {
      case 1:
        earlyFormSubmit({
          email,
        }); // Call earlyFormSubmit for the first step with req object
        return email; // Ensure email is filled out
      case 2:
        earlyFormSubmit({
          email,
          firstName,
          lastName,
        });
        return true;
      case 3:
        earlyFormSubmit({
          email,
          firstName,
          lastName,
          phone,
        });
        return true;
      case 4:
        earlyFormSubmit({
          email,
          firstName,
          lastName,
          phone,
          sex,
          // shirtSize,
        });
        return true;
      case 5:
        earlyFormSubmit({
          email,
          firstName,
          lastName,
          phone,
          sex,
          // shirtSize,
          message,
        });
        return true;
      case 6:
        earlyFormSubmit({
          email,
          firstName,
          lastName,
          phone,
          sex,
          // shirtSize,
          message,
          // map selectedEvents to an array of socialEventIds
          selectedEvents,
        });
        return true;
      case 7:
        // don't submit here because it will mess up combining veryInterestedInEvents and alsoVeryInterestedInEvents
        return true;
      case 8:
        // don't submit here because it will mess up final submission if something isn't different
        // earlyFormSubmit({
        //   email,
        //   firstName,
        //   lastName,
        //   phone,
        //   sex,
        //   shirtSize,
        //   message,
        //   selectedEvents,
        //   veryInterestedInEvents:
        //     veryInterestedInEvents.trim() +
        //     ", " +
        //     alsoVeryInterestedInEvents.trim(),
        // });
        return true;
      case 9:
        setFormEnabled(false);
        setProgressBarValue(0);
        return true;
      default:
        return false;
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            {/* email */}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter Email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                value={email}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            {/* first name */}
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="Enter First Name"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                autoComplete="off"
              />
            </div>

            {/* last name */}
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                autoComplete="off"
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            {/* phone number */}
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="number"
                className="form-control"
                id="phone"
                placeholder="Enter Phone Number"
                onChange={(e) => setPhone(e.target.value)}
                autoComplete="off"
                value={phone}
              />
            </div>
          </>
        );
      case 4:
        return (
          <>
            {/* sex - radio buttons: "male" and "female" */}
            <div className="form-group" style={{ textAlign: "center" }}>
              <label>Sex</label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transform: "scale(1.2)",
                }}
              >
                <div style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    id="male"
                    name="sex"
                    value="male"
                    onChange={() => setSex("male")}
                    checked={sex === "male"}
                  />
                  <label htmlFor="male">Male</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="female"
                    name="sex"
                    value="female"
                    onChange={() => setSex("female")}
                    checked={sex === "female"}
                  />
                  <label htmlFor="female">Female</label>
                </div>
              </div>
              <br />
            </div>

            {/* t shirt size select dropdown - XS, S, M, L, XL, XXL*/}
            <div className="form-group">
              <label htmlFor="shirtSize">T-Shirt Size</label>
              <select
                className="form-control"
                id="shirtSize"
                onChange={(e) => setShirtSize(e.target.value)}
                value={shirtSize}
              >
                <option value="XS">XS</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
              </select>
            </div>
            <br />
          </>
        );
      case 5:
        return (
          <>
            {/* message */}
            <div className="form-group">
              <label htmlFor="message">
                How did you hear about us? (optional)
              </label>

              <textarea
                type="text"
                className="form-control"
                id="message"
                placeholder="Enter Message"
                onChange={(e) => setMessage(e.target.value)}
                autoComplete="off"
                value={message}
              />
            </div>
          </>
        );
      case 6:
        return (
          <>
            {/* veryInterestedInEvents */}
            <div className="form-group">
              {events.length > 0 ? (
                <>
                  <label htmlFor="veryInterestedInEvents">
                    What upcoming events are you interested in? (optional)
                  </label>
                </>
              ) : null}
              {/* show upcoming events */}
              {upcomingEvents()}
            </div>
          </>
        );
      case 7:
        return (
          <>
            {/* interested event types label */}
            <label
              style={{
                lineHeight: "1",
                paddingTop: "10px",
                paddingBottom: "15px",
              }}
            >
              What do you like to do? <br />
              (optional)
            </label>
            {/* show interested event types */}
            {getInterestedEventTypes()}
          </>
        );
      case 8:
        return (
          <>
            <div>
              <label
                style={{
                  lineHeight: "1",
                  paddingTop: "20px",
                  paddingBottom: "15px",
                }}
              >
                Tell us any other events you are interested in here: (optional)
              </label>
              <textarea
                type="text"
                className="form-control"
                id="alsoVeryInterestedInEvents"
                placeholder="volleyball, pickleball, etc."
                onChange={(e) => setAlsoVeryInterestedInEvents(e.target.value)}
                autoComplete="off"
                value={alsoVeryInterestedInEvents}
              />
            </div>
            <br />
          </>
        );
      case 9:
        return (
          <div style={{ marginTop: "20px" }}>
            <p>Please verify your information below is correct</p>
            <p>First Name: {firstName}</p>
            <p>Last Name: {lastName}</p>
            <p>Phone: {phone}</p>
            <p>Email: {email}</p>
            <p>Sex: {sex}</p>
            <p>Shirt Size: {shirtSize}</p>
          </div>
        );
      default:
        return null;
    }
  };

  const getForm = () => {
    if (formEnabled) {
      return (
        <>
          {/* form with text box for QR code link */}
          <div className="form" style={{ marginTop: "-60px" }}>
            <img
              src={`https://s3.amazonaws.com/social-images-public/main/activebeaches_logo_cropped_03.png`}
              alt={`image-1`}
              style={{
                maxWidth: "100px", // make image responsive
                height: "auto", // auto adjust the height
              }}
            />
            <h3>Join Group</h3>
            <div className="step-progress-bar">
              <label>
                Step {currentStep} of {totalSteps}
              </label>
              <ProgressBar
                now={(currentStep / totalSteps) * 100}
                style={{ height: "20px", fontSize: "14px" }}
              />
            </div>
            <br />

            <form>
              {renderStep()}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              {(currentStep === totalSteps || currentStep === 1) && (
                <div
                  style={{
                    fontSize: "12px",
                    justifyContent: "center",
                    margin: "10px",
                    alignItems: "center",
                  }}
                >
                  By submitting this form, I agree to abide by the Active
                  Beaches terms of service found at{" "}
                  <a href="/tos">activebeaches.com/tos</a>&nbsp;receive emails
                  and texts from Active Beaches, to have photos taken at events
                  and be posted on social media and other marketing materials,
                  and to hold harmless Active Beaches and its partners and
                  organizers for any injuries or damages that may occur at
                  events.
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                {currentStep > 1 && (
                  <button
                    type="button"
                    className="btn btn-lg btn-block"
                    style={{
                      backgroundColor: "#d9d9d9",
                      color: "black",
                      border: "none",
                    }}
                    onClick={prevStep}
                  >
                    Previous
                  </button>
                )}
                {currentStep < totalSteps && currentStep !== 1 && (
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-primary"
                    onClick={nextStep}
                  >
                    Next
                  </button>
                )}
                {/* special submit for currentStep === 1 to agree but continue on to the next step without submitting the form the original way */}
                {currentStep === 1 && (
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-primary"
                    style={{ fontSize: "20px" }}
                    onClick={nextStep}
                  >
                    I agree, submit
                  </button>
                )}

                {currentStep === totalSteps && (
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-primary"
                    style={{ fontSize: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      onFormSubmit(e);
                    }}
                  >
                    I agree, submit
                  </button>
                )}
              </div>

              <div
                style={{ marginBottom: "20px" }} // 20px space between images
              >
                <img
                  src={`https://s3.amazonaws.com/social-images-public/main/${currentStep}.jpg`}
                  alt={`image-7`}
                  style={{
                    maxWidth: "100%", // make image responsive
                    height: "auto", // auto adjust the height
                    borderRadius: "15px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    border: "3px solid #e6e6e6",
                    marginTop: "60px",
                  }}
                />
              </div>

              {formError ? (
                <div
                  id="error-message"
                  className="alert alert-danger"
                  role="alert"
                >
                  <strong>Error!</strong> {formError}
                </div>
              ) : null}
            </form>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="outer-page-container">
      <div className="page">
        <div
          style={{
            padding: "10px",
            borderRadius: "5px",
            marginLeft: "auto",
            marginTop: "20px",
          }}
        >
          {/* Error Message Display Div */}
          {formError ? (
            <div
              id="error-message"
              className="alert alert-danger"
              role="alert"
              style={{ marginBottom: "60px" }}
            >
              <strong>Error!</strong>
              <br />
              <p>
                There was a problem submitting the form. Please try again and be
                sure to fill out every field in the form.
              </p>
              {/* button to navigate to reload again */}
              <button
                type="button"
                className="btn btn-sm btn-primary"
                style={{ fontSize: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload();
                }}
              >
                Try Again
              </button>
              <br />
              <br />
              {/* button to show more details */}
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                style={{ fontSize: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowFormError(!showFormError);
                }}
              >
                Show Error Details
              </button>
              {/* Error Message Display Div in 12px font */}
              {showFormError ? (
                <div
                  id="error-message"
                  className="alert alert-danger"
                  role="alert"
                  style={{ fontSize: "12px" }}
                >
                  {formError}
                </div>
              ) : null}
            </div>
          ) : null}

          {/* Success Message Display Div */}
          {formSuccess ? (
            <div
              id="success-message"
              className="alert alert-success"
              role="alert"
            >
              <strong>Success!</strong> We got your request to join our group!
              <p>
                The cost to join the group is $
                {process.env.REACT_APP_MEMBERSHIPCOST_USD} per month.
              </p>
              <p>
                Click the button below to be redirected to the subscribe page.
              </p>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                style={{ fontSize: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/subscribe");
                }}
              >
                Go to Payment Page
              </button>
            </div>
          ) : null}

          {/* Loading Message Display Div */}
          {!formEnabled && !formError && progressBarValue != 100 ? (
            <>
              <div
                id="loading-message"
                className="alert alert-info"
                role="alert"
              >
                <strong>
                  If you are not shown a success message within 20 seconds,
                  please refresh the page and try again.
                </strong>
              </div>
              {/* create a loading spinner with inline css */}
              <ProgressBar now={progressBarValue} animated />
            </>
          ) : null}
        </div>

        {getForm()}

        <br />
        <div style={{ padding: "10px" }}>
          <br />
          <br />
          {SocialLinks()}
        </div>

        {/* footer */}
        {/* <div className="footer">
          <p>&copy; {new Date().getFullYear()} Beach Social Group</p>
          <p>
            <a
              href="http://davidhudman.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Designed by David Hudman
            </a>
          </p>
        </div> */}
      </div>
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  ArrowLeft,
  Check,
  X,
  Calendar,
  Facebook,
  Instagram,
} from "lucide-react";
// import the stripe buy button
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";
import Breadcrumb from "../../other/Breadcrumb/Breadcrumb";

import "./subscribe.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "main/1.jpg",
  "main/2.jpg",
  "main/3.jpg",
  "main/4.jpg",
  "main/5.jpg",
  "main/6.jpg",
  "main/7.jpg",
  "main/8.jpg",
  "main/9.jpg",
  "main/10.jpg",
  "main/11.jpg",
  "main/12.jpg",
  "main/13.jpg",
];

const PHOTOS = [
  {
    url: "https://s3.amazonaws.com/social-images-public/main/1.jpg",
    caption: "Beach Volleyball",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/40.jpg",
    caption: "Pickleball",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/33.jpg",
    caption: "Running Groups",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/18.jpg",
    caption: "Rock Climbing",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/7.jpg",
    caption: "Fun Times",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/14.jpg",
    caption: "Good Vibes",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/8.jpg",
    caption: "Art Walks in St Aug",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/30.jpg",
    caption: "Country Dance",
  },
];

// const images = [
//   "top_golf_0001.jpg",
//   "sip_stroll_0001.jpg",
//   "beach_volleyball_0002.jpg",
//   "frisbee_0003.jpg",
//   "silent_disco_ladies_0001.jpg",
// ];

const breadcrumbItems = [
  { label: "Home", url: "/" },
  { label: "Subscribe", url: "/subscribe", active: true },
];

// Define membership tiers for the comparison table
const tiers = [
  {
    name: "Supporting Members",
    id: "supporter",
    price: 20,
    features: [
      { name: "All Event Locations", included: true },
      { name: "Group chat", included: true },
      { name: "Event polls with RSVPs", included: true },
    ],
  },
  {
    name: "Non-members",
    id: "nonmembers",
    price: 0,
    features: [
      {
        name: "Limited Event Locations for Public Events Only",
        included: true,
      },
    ],
  },
];

const benefitsPhoto =
  "https://s3.amazonaws.com/social-images-public/main/12.jpg";
const communityPhoto =
  "https://s3.amazonaws.com/social-images-public/main/14.jpg";

// Toggle this variable to switch between photo and gradient backgrounds
const useParallaxPhotos = false;

function ParallaxSection({ imageUrl, gradient, children, id }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <div className="position-relative">
      <div
        id={id}
        className={`position-absolute w-100 h-100 ${
          !isMobile ? "bg-fixed" : ""
        }`}
        style={{
          backgroundImage: useParallaxPhotos ? `url('${imageUrl}')` : gradient,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div className="position-absolute w-100 h-100 bg-dark opacity-50" />
      <div className="position-relative d-flex align-items-center justify-content-center py-5">
        {children}
      </div>
    </div>
  );
}

const Subscribe = () => {
  const [showCosts, setShowCosts] = useState(false);
  const costsRef = useRef(null);

  useEffect(() => {
    if (costsRef.current) {
      costsRef.current.style.maxHeight = showCosts
        ? `${costsRef.current.scrollHeight}px`
        : "0px";
    }
  }, [showCosts]);

  const toggleCosts = () => {
    setShowCosts(!showCosts);
  };

  const supporterTier = tiers.find((tier) => tier.id === "supporter");

  return (
    <div className="min-vh-100">
      {/* Hero Section */}
      <ParallaxSection
        imageUrl={benefitsPhoto}
        gradient="linear-gradient(to right, #ff7e5f, #feb47b)"
        id="subscribe-hero"
      >
        <div className="text-center text-white max-w-4xl px-4">
          <h2 className="mb-4">Can you help support this awesome group?</h2>
          <button
            className="btn btn-link text-white"
            style={{ fontSize: "16px" }}
            onClick={toggleCosts}
            aria-expanded={showCosts}
            aria-controls="costs-list"
          >
            {showCosts
              ? "Hide Our Annual Budget ▲"
              : "Show Our Annual Budget ▼"}
          </button>
          <div
            id="costs-list"
            ref={costsRef}
            className="overflow-hidden"
            style={{
              maxHeight: "0px",
              transition: "max-height 0.5s ease",
            }}
          >
            <ul className="list-unstyled mb-4" style={{ fontSize: "16px" }}>
              <li>$3,000 Sports Equipment</li>
              <li>$2,000 Incorporation Expenses</li>
              <li>$1,000 Various Activities Supplies</li>
              <li>$1,000 Web Hosting</li>
              <li>$1,000 Beach Permits</li>
              <li>$1,000 Advertisement</li>
              <li>$300 Meetup.com fees</li>
              <li>$700 Group Communication Costs</li>
              {/* total */}
              <li>
                <strong>TOTAL: $10,000 Annually</strong>
              </li>
              <li>1,000+ of hours on my part planning and organizing</li>
            </ul>
          </div>
        </div>
      </ParallaxSection>

      {/* Pricing Table Section */}
      <div className="bg-light py-5">
        <div className="container">
          <div className="row">
            {tiers.map((tier) => (
              <div key={tier.name} className="col-md-6 mb-4">
                {tier.id !== "nonmembers" ? (
                  <Link
                    to={`/subscribe/${tier.id.toLowerCase()}`}
                    className="text-decoration-none"
                  >
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <h3 className="card-title">{tier.name}</h3>
                        <p className="card-text display-4">${tier.price}/mo</p>
                        <ul className="list-unstyled">
                          {tier.features.map((feature) => (
                            <li
                              key={feature.name}
                              className="d-flex align-items-center mb-2"
                            >
                              {feature.included ? (
                                <Check className="text-success mr-2" />
                              ) : (
                                <X className="text-danger mr-2" />
                              )}
                              <span>{feature.name}</span>
                            </li>
                          ))}
                        </ul>
                        {/* fake button with text "Subscribe" */}
                        <button className="btn btn-primary">Subscribe</button>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h3 className="card-title">{tier.name}</h3>
                      <p className="card-text display-4">${tier.price}/mo</p>
                      <ul className="list-unstyled">
                        {tier.features.map((feature) => (
                          <li
                            key={feature.name}
                            className="d-flex align-items-center mb-2"
                          >
                            {feature.included ? (
                              <Check className="text-success mr-2" />
                            ) : (
                              <X className="text-danger mr-2" />
                            )}
                            <span>{feature.name}</span>
                          </li>
                        ))}
                      </ul>
                      {/* fake button with text "Continue with Limited Access" then say no action required (in small text)) */}
                      <p className="text-muted small py-3">
                        No action required.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Photo Grid Section */}
      <div className="photo-grid">
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {PHOTOS.slice(5, 6).map((photo, index) => (
              <div key={index} className="group">
                <div className="aspect-square overflow-hidden rounded-lg mb-3">
                  <img
                    src={photo.url}
                    alt={photo.caption}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <p className="text-center text-gray-700 font-medium">
                  {photo.caption}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Explanation Section */}
      <div className="bg-white py-5">
        <div className="container">
          <div className="mx-auto text-center">
            <p className="text-muted small mb-3">
              The chat is where we post event locations. It's just for
              announcements.
            </p>
            <p className="text-muted small">
              To view event locations in the GroupMe announcements-only chat,
              you must be a member. Members must pay a monthly $20 subscription
              fee using the button below. Non-members will only be able to see
              some of the upcoming events. Members will be able to see all
              upcoming events and location details.
            </p>
          </div>
        </div>
      </div>

      {/* Photo Grid Section */}
      <div className="photo-grid">
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {PHOTOS.slice(0, 1).map((photo, index) => (
              <div key={index} className="group">
                <div className="aspect-square overflow-hidden rounded-lg mb-3">
                  <img
                    src={photo.url}
                    alt={photo.caption}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <p className="text-center text-gray-700 font-medium">
                  {photo.caption}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Second Parallax Section */}
      <ParallaxSection
        imageUrl={communityPhoto}
        gradient="linear-gradient(to right, #6a11cb, #2575fc)"
        id="value-parallax"
      >
        <div className="text-center text-white max-w-3xl px-4">
          <p className="small text-white">
            Since this is basically the cost of a drink or two at a bar, our
            commitment to you is that we will always provide a better time than
            a bar or restaurant. And that these events will keep you active
            while having fun and making new friends.
          </p>
        </div>
      </ParallaxSection>

      {/* Photo Grid Section */}
      <div className="photo-grid">
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {PHOTOS.slice(1, 2).map((photo, index) => (
              <div key={index} className="group">
                <div className="aspect-square overflow-hidden rounded-lg mb-3">
                  <img
                    src={photo.url}
                    alt={photo.caption}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <p className="text-center text-gray-700 font-medium">
                  {photo.caption}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Final CTA Section */}
      <div className="bg-light py-5">
        <div className="container">
          <div className="mx-auto text-center">
            <p className="text-muted small">
              We know this seems like a big ask at first, but this is a small
              portion of the monthly costs to run the group to purchase supplies
              for events, extra pickleball paddles, volleyballs, Meetup
              organizer subscription fees, etc. So we're asking the members to
              share that with us whether they are playing or just watching and
              hanging out.
            </p>
            <p className="text-muted small">
              We're asking everyone to step up with us to provide for nice
              events. If you would be willing to join in as a supporter, it
              would really mean a lot and would help out the group.
            </p>
          </div>
        </div>
      </div>

      {/* Supporter Card Section */}
      <div className="bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 mb-4">
              <Link
                to={`/subscribe/${supporterTier.name.toLowerCase()}`}
                className="text-decoration-none"
              >
                <div className="card shadow-sm">
                  <div className="card-body">
                    <h3 className="card-title">{supporterTier.name}</h3>
                    <p className="card-text display-4">
                      ${supporterTier.price}/mo
                    </p>
                    <ul className="list-unstyled">
                      {supporterTier.features.map((feature) => (
                        <li
                          key={feature.name}
                          className="d-flex align-items-center mb-2"
                        >
                          {feature.included ? (
                            <Check className="text-success mr-2" />
                          ) : (
                            <X className="text-danger mr-2" />
                          )}
                          <span>{feature.name}</span>
                        </li>
                      ))}
                    </ul>
                    {/* fake button with text "Subscribe" */}
                    <button className="btn btn-primary">Subscribe</button>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-white py-4">
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            {/* Site Links */}
            {/* <div className="col-12 col-md-auto mb-3 mb-md-0">
              <div className="d-flex flex-column flex-md-row justify-content-center">
                <Link to="/about" className="text-white me-3 mb-2 mb-md-0">
                  About
                </Link>
                <Link to="/signup" className="text-white me-3 mb-2 mb-md-0">
                  Join Group
                </Link>
                <Link to="/photos" className="text-white me-3 mb-2 mb-md-0">
                  Photos
                </Link>
                <Link
                  to="/signin"
                  className="text-white d-flex align-items-center"
                >
                  <LogIn size={18} className="me-1" />
                  Login
                </Link>
              </div>
            </div> */}

            {/* Social Links */}
            <div className="col-12 col-md-auto">
              <div className="d-flex justify-content-center">
                <a
                  href="https://meetup.com/activebeaches"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mx-3" // Use mx-3 for more spacing
                >
                  <Calendar size={24} />
                </a>
                <a
                  href="https://facebook.com/activebeaches"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mx-3" // Use mx-3 for more spacing
                >
                  <Facebook size={24} />
                </a>
                <a
                  href="https://instagram.com/activebeaches"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mx-3" // Use mx-3 for more spacing
                >
                  <Instagram size={24} />
                </a>
              </div>
            </div>
          </div>
          {/* old stuff */}
          <div className="text-center mt-5">
            {/* links to data deletion page, and privacy page, and terms page */}
            <p>
              <a href="/data-deletion" className="text-white">
                Data Deletion
              </a>{" "}
              |{" "}
              <a href="/privacy" className="text-white">
                Privacy
              </a>{" "}
              |{" "}
              <a href="/terms" className="text-white">
                Terms
              </a>{" "}
              |{" "}
              <a href="/unsubscribe" className="text-white">
                Unsubscribe
              </a>
              <br />
              &copy; {new Date().getFullYear()}{" "}
              {process.env.REACT_APP_GROUP_NAME}
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Subscribe;

const WomenSubscription = () => {
  return (
    <div>
      {/* womens verification process */}
      <div style={{ textAlign: "justify", textAlignLast: "center" }}>
        <h4
          style={{
            marginTop: "-10px",
            marginBottom: "-10px",
            fontWeight: "bold",
          }}
        >
          Women's Verification Options
        </h4>
        <hr />
        <p style={{ lineHeight: 1.1 }}>
          Option 1: Get recommendation from any woman or admin in the group
        </p>
        <p style={{ lineHeight: 1.1 }}>
          Option 2:{" "}
          <a
            href={
              "https://instagram.com/" + process.env.REACT_APP_INSTAGRAM_HANDLE
            }
            target="_blank"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Follow us
          </a>{" "}
          on Instagram, and approve us to follow you so we can see that there
          are photos of you from a while back and it seems legit to us.
        </p>
        <p style={{ lineHeight: 1.1 }}>
          Option 3: Meet us at a public event so we can see that you are who you
          say you are.
        </p>
        <p style={{ lineHeight: 1.1 }}>
          Option 4: Follow the{" "}
          <a
            href="/membership-men"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            mens registration
          </a>{" "}
          process, and pay the monthly fee. Once we meet you at a public or
          private event, you will no longer be required to pay the monthly fee.
        </p>
      </div>
    </div>
  );
};

const NoSubscriptionSelectedDiv = () => {
  return (
    <div>
      <p style={{ lineHeight: 1.1 }}>
        Event locations are shown to members only. <br />{" "}
        <span
          style={{
            fontStyle: "italic",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Women join free.
        </span>{" "}
        Men pay ${process.env.REACT_APP_MEMBERSHIPCOST_USD} / mo.
      </p>
      <p style={{ lineHeight: 1.1 }}>
        We see this fee as a challenge - an opportunity to step up, support our
        community, and show respect for our female members. Are you ready to
        join our ranks? See you at the next event 🥳
      </p>
    </div>
  );
};

const Subscription = () => {
  return (
    <div>
      <div
        style={{
          textAlign: "justify",
          textAlignLast: "center",
          padding: "20px",
        }}
      >
        <h4
          style={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            marginBottom: "0.5rem",
          }}
        >
          Membership
        </h4>
        <hr style={{ marginBottom: "1rem" }} />
        <p style={{ lineHeight: 1.5 }}>
          <a href="/unsubscribe">UNSUBSCRIBE HERE</a>.
        </p>
        <p style={{ lineHeight: 1.5 }}>Subscribe below.</p>

        <StripeBuyButton />
        <table className="benefits-table">
          <thead>
            <tr>
              <th>Benefit</th>
              <th>
                Non-members
                <br />
                $0 / mo
              </th>
              <th>
                Members
                <br />${process.env.REACT_APP_MEMBERSHIPCOST_USD} / mo
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>All Event Locations</td>
              <td>Limited Event Locations for Public Events Only</td>
              <td>✅</td>
            </tr>
            <tr>
              <td>Group chat</td>
              <td>❌</td>
              <td>✅</td>
            </tr>
            <tr>
              <td>Event polls with RSVPs</td>
              <td>❌</td>
              <td>✅</td>
            </tr>
          </tbody>
        </table>
        <p style={{ lineHeight: 1.5 }}>
          The chat is where we post event locations. It's just for
          announcements.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          To view event locations in the GroupMe announcements-only chat, you
          must be a member. Members must pay a monthly $
          {process.env.REACT_APP_MEMBERSHIPCOST_USD} subscription fee{" "}
          <a
            href="https://buy.stripe.com/8wMdUS74j9nA5sA289"
            target="_blank"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            HERE
          </a>
          . Everyone will be able to see titles of upcoming events, but only
          members will be able to see the locations of certain events.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          We know this seems like a big ask at first, but this is a small
          portion of the monthly costs to run the group to purchase supplies for
          events, extra pickleball paddles, volleyballs, Meetup organizer
          subscription fees, etc. So we're asking the members to share that with
          us whether they are playing or just watching and hanging out. We're
          asking everyone to step up with us to provide for nice events.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          Again, we know it's a lot to ask, but if you would be willing to join
          in as a supporter, it would really mean a lot and would help out the
          group.
        </p>
        <p style={{ lineHeight: 1.5 }}>
          Since this is basically the cost of a drink or two at a bar, our
          commitment to you is that we will always provide a better time than a
          bar or restaurant. And that these events will keep you active while
          having fun and making new friends.
        </p>
        <p style={{ lineHeight: 1.5, display: "none" }}>
          If anyone pays in Bitcoin Cash, they only pay $
          {process.env.REACT_APP_MEMBERSHIPCOST_BCH} per month, but they must
          pay in 6-month increments - $
          {process.env.REACT_APP_MEMBERSHIPCOST_BCH * 6} total. You can pay with
          Bitcoin Cash{" "}
          <a
            href="/paybch"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            HERE
          </a>
          . You may have to contact me after you've paid so I can verify the
          payment status and add you as a member to our group, so please contact
          the group account on Instagram by{" "}
          <a
            href={
              "https://instagram.com/" + process.env.REACT_APP_INSTAGRAM_HANDLE
            }
            target="_blank"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            following us
          </a>{" "}
          and sending a direct message.
        </p>
      </div>

      {/* stripe buy button */}
      <StripeBuyButton />
    </div>
  );
};

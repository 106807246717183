import React, { useState, useEffect } from "react"; // Fragment
import {
  // BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import SocialLinks from "../../other/SocialLinks/SocialLinks";
import { Facebook, Instagram, LogIn, Calendar } from "lucide-react";

import "./home.css";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "main/1.jpg",
  "main/2.jpg",
  "main/3.jpg",
  "main/4.jpg",
  "main/5.jpg",
  "main/6.jpg",
  "main/7.jpg",
  "main/8.jpg",
  "main/9.jpg",
  "main/10.jpg",
  "main/11.jpg",
  "main/12.jpg",
  "main/13.jpg",
];

const parallaxPhotos = [
  // {
  //   imageUrl: "https://s3.amazonaws.com/social-images-public/main/15.jpg",
  //   subject: "Bonfire",
  // },
  {
    imageUrl: "https://s3.amazonaws.com/social-images-public/main/3.jpg",
    subject: "Volleyball Rainbow Spike",
  },
  {
    imageUrl: "https://s3.amazonaws.com/social-images-public/main/35.jpg",
    subject: "Volleyball Sunset",
  },
  // {
  //   imageUrl: "https://s3.amazonaws.com/social-images-public/main/26.jpg",
  //   subject: "Bonfire",
  // },
  {
    imageUrl: "https://s3.amazonaws.com/social-images-public/main/14.jpg",
    subject: "Bonfire Nighttime",
  },
];

const PHOTOS = [
  {
    url: "https://s3.amazonaws.com/social-images-public/main/1.jpg",
    caption: "Beach Volleyball",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/40.jpg",
    caption: "Pickleball",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/33.jpg",
    caption: "Running Groups",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/18.jpg",
    caption: "Rock Climbing",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/7.jpg",
    caption: "Fun Times",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/14.jpg",
    caption: "Good Vibes",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/8.jpg",
    caption: "Art Walks in St Aug",
  },
  {
    url: "https://s3.amazonaws.com/social-images-public/main/30.jpg",
    caption: "Country Dance",
  },
];

// [
//   "sip_stroll_0001.jpg",
//   "skate_0001.jpg",
//   "top_golf_0001.jpg",

//   "beach_volleyball_0002.jpg",
//   "frisbee_0003.jpg",
//   "silent_disco_ladies_0001.jpg",
// ];

const EVENT_API_URL = `https://4cljs7mcdi.execute-api.us-east-1.amazonaws.com/default/socialevents`;

// Toggle this variable to switch between photo and gradient backgrounds
const useParallaxPhotos = false;

function ParallaxSection({ imageUrl, gradient, height = "100vh", children }) {
  return (
    <div className="parallax-section" style={{ height }}>
      <div
        className="parallax-background"
        style={{
          backgroundImage: useParallaxPhotos ? `url('${imageUrl}')` : gradient,
        }}
      />
      <div className="parallax-content">{children}</div>
    </div>
  );
}

const Home = () => {
  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [isLoaded, setIsLoaded] = useState(true);

  const { eventId } = useParams();

  const navigate = useNavigate();

  const today = new Date();
  const havana2024NovEndDate = new Date("2024-11-30");

  // useEffect(() => {
  //   const img = new Image();
  //   img.src = "https://s3.amazonaws.com/social-images-public/main/15.jpg";
  //   img.onload = () => setIsLoaded(true);

  //   const timer = setTimeout(() => {
  //     setIsLoaded(true);
  //   }, 5000); // 5 seconds timeout

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    async function fetchData() {
      // create a mock function that will return a list of events after a delay
      let useMocks = false;
      if (!useMocks) {
        if (eventId) {
          // get events from this api - specific event
          await fetch(`${EVENT_API_URL}?id=${eventId}`)
            .then((res) => res.json())
            .then((data) => {
              console.log("specific event data: ", data);
              if (data && data.socialEvents && data.socialEvents.Item) {
                console.log("setting event: ", data.socialEvents.Item);
                setEvent(data.socialEvents.Item);
              } else {
              }
            })
            .catch((err) => {
              console.log("err", err);
              return [];
            });
        }
        // get events from this api - all public events
        await fetch(EVENT_API_URL)
          .then((res) => res.json())
          .then((data) => {
            console.log("public events data: ", data);
            if (data && data.socialEvents) {
              // Extract date from socialEventId and sort events by this date
              data.socialEvents.sort((a, b) => {
                const dateA = new Date(
                  a.socialEventId.split("-").slice(0, 3).join("-")
                );
                const dateB = new Date(
                  b.socialEventId.split("-").slice(0, 3).join("-")
                );
                return dateA - dateB;
              });
              setEvents(data.socialEvents);

              // Set current events to only include events that have not passed
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              const currentEvents = data.socialEvents.filter((eventItem) => {
                const eventDate = new Date(
                  eventItem.socialEventId.split("-").slice(0, 3).join("-")
                );
                if (eventDate < yesterday) {
                  return false;
                }
                return true;
              });
              setCurrentEvents(currentEvents);
            } else {
              console.log(
                "called public event api, but no events were returned"
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
            return [];
          });
      }
    }
    fetchData();
  }, []);

  // function to convert date "May 29, 2023" to "Mon May 29"
  const convertDate = (date) => {
    const d = new Date(date);
    const day = d.toLocaleString("default", { weekday: "short" });
    const month = d.toLocaleString("default", { month: "short" });
    const dayOfMonth = d.getDate();
    return `${day} ${month} ${dayOfMonth}`;
  };

  const membershipCard = () => (
    <a
      href="/memberships"
      rel="noopener noreferrer"
      className="btn btn-lg btn-block btn-success custom-btn"
    >
      <div>Join as Member</div>
      <div style={{ fontSize: ".6em" }}>
        men ${process.env.REACT_APP_MEMBERSHIPCOST_USD} / mo
      </div>
      <div
        style={{
          fontSize: ".6em",
          fontStyle: "italic",
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        women FREE
      </div>
    </a>
  );

  const upcomingEvents = () => (
    <>
      <div className="home" style={{ paddingBottom: "10px" }}>
        {currentEvents.length > 0 ? (
          <>
            <span style={{ paddingRight: "0px" }}>
              {/* display each element in events array in a compact bootstrap card */}
              {currentEvents.slice(0, 3).map((eventItem, i) => {
                // if date is before yesterday, don't show it
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const eventDate = new Date(eventItem.date);
                if (eventDate < yesterday) {
                  return null;
                }
                return (
                  <button
                    onClick={() =>
                      navigate(`/events/${eventItem.socialEventId}`)
                    }
                    key={i}
                    className="text-decoration-none text-dark custom-button"
                  >
                    <div className="button-content">
                      <small className="font-weight-bold">
                        {eventItem.title}
                      </small>
                      <br />
                      <small className="text-muted">
                        {convertDate(eventItem.date)} @ {eventItem.time}
                      </small>
                      <br />
                      <small className="text-muted">
                        @ {eventItem.location}
                      </small>
                    </div>
                    <div className="button-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="purple"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{
                          background: "#FFD166",
                          borderRadius: "50%",
                          padding: "0px",
                        }}
                      >
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <polyline points="12 5 19 12 12 19" />
                      </svg>
                    </div>
                  </button>
                );
              })}
            </span>
          </>
        ) : (
          <>
            {events.length < 1 ? (
              <div className="text-center">
                <i className="fa fa-spinner fa-spin fa-3x"></i>
              </div>
            ) : (
              <div className="text-center">
                <p>Calendar empty...will be filled soon!</p>
              </div>
            )}
          </>
        )}

        {/* <button
          onClick={() => navigate("/events")}
          className="btn btn-block"
          style={{
            backgroundColor: "#71368A",
            color: "#FFFFFF",
            fontSize: "24px",
            width: "100%",
            marginTop: "5px",
            height: "48px",
          }}
        >
          Full Event List
        </button> */}
      </div>
    </>
  );

  // parallax section
  const parallaxJoinSection = (photo, gradient) => (
    <ParallaxSection imageUrl={photo.imageUrl} gradient={gradient}>
      <div className="text-center text-white">
        <h1 className="font-display text-8xl mb-4 tracking-wider">
          Active Beaches
        </h1>
        <h3 className="font-light text-xl md:text-2xl mb-12 tracking-wide">
          Get Active. Make Friends.
        </h3>
        <Link to="/join" className="join-button">
          JOIN
        </Link>
      </div>
    </ParallaxSection>
  );

  return (
    <div>
      {/* First Parallax Section */}
      {parallaxJoinSection(
        parallaxPhotos[0],
        "linear-gradient(to right, #ff7e5f, #feb47b)"
      )}

      {/* Photo Grid Section */}
      <div className="photo-grid">
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {PHOTOS.slice(0, 2).map((photo, index) => (
              <div key={index} className="group">
                <div className="overflow-hidden rounded-lg mb-3">
                  <img
                    src={photo.url}
                    alt={photo.caption}
                    className="w-full h-full object-cover transition-transform duration-300"
                  />
                </div>
                <p className="text-center text-gray-700 font-medium">
                  {photo.caption}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Second Parallax Section */}
      {parallaxJoinSection(
        parallaxPhotos[1],
        "linear-gradient(to right, #6a11cb, #2575fc)"
      )}

      {/* Photo Grid Section */}
      <div className="photo-grid">
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {PHOTOS.slice(2, 4).map((photo, index) => (
              <div key={index} className="group">
                <div className="aspect-square overflow-hidden rounded-lg mb-3">
                  <img
                    src={photo.url}
                    alt={photo.caption}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <p className="text-center text-gray-700 font-medium">
                  {photo.caption}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Third Parallax Section */}
      {parallaxJoinSection(
        parallaxPhotos[2],
        "linear-gradient(to right, #00c6ff, #0072ff)"
      )}

      {/* Photo Grid Section */}
      <div className="photo-grid">
        <div className="container mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {PHOTOS.slice(4, 6).map((photo, index) => (
              <div key={index} className="group">
                <div className="aspect-square overflow-hidden rounded-lg mb-3">
                  <img
                    src={photo.url}
                    alt={photo.caption}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <p className="text-center text-gray-700 font-medium">
                  {photo.caption}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <footer className="bg-dark text-white py-4">
        <div className="container">
          <div className="row justify-content-center align-items-center text-center">
            {/* Site Links */}
            {/* <div className="col-12 col-md-auto mb-3 mb-md-0">
              <div className="d-flex flex-column flex-md-row justify-content-center">
                <Link to="/about" className="text-white me-3 mb-2 mb-md-0">
                  About
                </Link>
                <Link to="/signup" className="text-white me-3 mb-2 mb-md-0">
                  Join Group
                </Link>
                <Link to="/photos" className="text-white me-3 mb-2 mb-md-0">
                  Photos
                </Link>
                <Link
                  to="/signin"
                  className="text-white d-flex align-items-center"
                >
                  <LogIn size={18} className="me-1" />
                  Login
                </Link>
              </div>
            </div> */}

            {/* Social Links */}
            <div className="col-12 col-md-auto">
              <div className="d-flex justify-content-center">
                <a
                  href="https://meetup.com/activebeaches"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mx-3" // Use mx-3 for more spacing
                >
                  <Calendar size={24} />
                </a>
                <a
                  href="https://facebook.com/activebeaches"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mx-3" // Use mx-3 for more spacing
                >
                  <Facebook size={24} />
                </a>
                <a
                  href="https://instagram.com/activebeaches"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white mx-3" // Use mx-3 for more spacing
                >
                  <Instagram size={24} />
                </a>
              </div>
            </div>
          </div>
          {/* old stuff */}
          <div className="text-center mt-5">
            {/* links to data deletion page, and privacy page, and terms page */}
            <p>
              <a href="/data-deletion" className="text-white">
                Data Deletion
              </a>{" "}
              |{" "}
              <a href="/privacy" className="text-white">
                Privacy
              </a>{" "}
              |{" "}
              <a href="/terms" className="text-white">
                Terms
              </a>{" "}
              |{" "}
              <a href="/unsubscribe" className="text-white">
                Unsubscribe
              </a>
              <br />
              &copy; {new Date().getFullYear()}{" "}
              {process.env.REACT_APP_GROUP_NAME}
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;

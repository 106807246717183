import React, { useState, useEffect } from "react";

import "./createevent.css";

const CreateEvent = () => {
  const [title, setTitle] = useState("");
  // Get current date in US Eastern Time and set time to 6 PM
  const easternTime = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  const defaultDateTime = new Date(easternTime);
  defaultDateTime.setHours(18, 0, 0, 0); // Set time to 6 PM
  const [dateTime, setDateTime] = useState(defaultDateTime.toISOString());
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [length, setLength] = useState("");
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [locationSearch, setLocationSearch] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [previousEvents, setPreviousEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [password, setPassword] = useState("");
  const [generalPublic, setGeneralPublic] = useState(false);
  const [membersOnly, setMembersOnly] = useState(true);
  const [pollPrivateLocation, setPollPrivateLocation] = useState("");
  const [pollText, setPollText] = useState("");
  const [hostName, setHostName] = useState("");
  const [yourName, setYourName] = useState("");
  const [yourPhoneNumber, setYourPhoneNumber] = useState("");
  const [chat, setChat] = useState("");
  const [groupmeImg, setGroupmeImg] = useState("");

  const imageOptions = [
    { path: "main/concert_001.jpg", alias: "Generic Event" },
    { path: "main/medium_run_group_001.jpeg", alias: "Run Group" },
    { path: "main/medium_pickleball_001.jpeg", alias: "Pickleball" },
    {
      path: "main/medium_beach_volleyball_group_20240506a.jpeg",
      alias: "Volleyball",
    },
    { path: "main/country_dance_0002.jpg", alias: "Country Dance" },
    { path: "spanish_conversation_0001.jpg", alias: "Spanish Group" },
    { path: "main/rock_climb_001.jpg", alias: "Rock Climbing" },
    { path: "main/top_golf_0001.jpg", alias: "Golf Driving Range" },
    {
      path: "main/sunrise-beach-worship-ai-01.jpg",
      alias: "Beach Worship or Church",
    },
    { path: "main/12.jpg", alias: "Brewery Hangout" },
    { path: "main/medium_art_walk_001.jpeg", alias: "Art Walk" },
    { path: "main/dance-salsa-havana-nights-012.jpeg", alias: "Salsa Dance" },
    { path: "main/bonfire_2024_jan_0001.jpg", alias: "Bonfire" },
  ];

  const locationOptions = [
    {
      value: "Jarboe Park, 510 Florida Blvd, Neptune Beach, FL 32266",
      alias: "Jarboe Park",
    },
    {
      value: "Margaritaville, 715 1st St N, Jacksonville Beach, FL 32250",
      alias: "Margaritaville Jax Beach",
    },
    {
      value: "Veterans Park, 1332 Veterans Pkwy, St Johns, FL 32259",
      alias: "Veterans Park",
    },
    {
      value: "K1 Speed, 6601 Executive Park Ct N, Jacksonville, FL 32216",
      alias: "K1 Speed",
    },
    {
      value: "Fort Family Park, 8000 Baymeadows Rd E, Jacksonville, FL 32256",
      alias: "Fort Family Park",
    },
    {
      value:
        "Florida Blvd Beach Access, 100 Florida Blvd, Neptune Beach, FL 32266",
      alias: "Florida Blvd Beach Access",
    },
    {
      value: "1937 Spirits & Eatery, 1842 Kings Ave, Jacksonville, FL 32207",
      alias: "1937 Spirits & Eatery",
    },
    {
      value: "Aardwolf Brewing Co, 1461 Hendricks Ave, Jacksonville, FL 32207",
      alias: "Aardwolf Brewing Co",
    },
    {
      value: "Alien Taco, 7643 Gate Pkwy, Jacksonville, FL 32256",
      alias: "Alien Taco",
    },
    {
      value: "Artessence Coffee Shop, 1505 N Main St, Jacksonville, FL 32206",
      alias: "Artessence Coffee Shop",
    },
    {
      value: "Beaches Rock Gym, 14 W 3rd St, Atlantic Beach, FL 32233",
      alias: "Beaches Rock Gym",
    },
    {
      value: "Church of Eleven22, 14286 Beach Blvd, Jacksonville, FL 32250",
      alias: "Church of Eleven22",
    },
    {
      value: "Cuisine 76 and Bar, 1023 Park St, Jacksonville, FL 32204",
      alias: "Cuisine 76 and Bar",
    },
    {
      value:
        "Culhane's Irish Pub: Southside, 9720 Deer Lake Court, Jacksonville, FL",
      alias: "Culhane's Irish Pub: Southside",
    },
    {
      value: "Edge Rock Gym, 3563 Philips Hwy #702, Jacksonville, FL 32207",
      alias: "Edge Rock Gym",
    },
    {
      value: "F45 Baymeadows, 8738 Baymeadows Rd E, Jacksonville, FL 32256",
      alias: "F45 Baymeadows",
    },
    {
      value:
        "First Christian Church of the Beaches, 2125 Ocean Front, Neptune Beach, FL 32266",
      alias: "First Christian Church of the Beaches",
    },
    {
      value:
        "Fishweir Brewing Company, 1183 Edgewood Avenue S, Jacksonville, FL US 32205",
      alias: "Fishweir Brewing Company",
    },
    {
      value: "Flynn Park, 2738 Orange Picker Rd, Jacksonville, FL 32223",
      alias: "Flynn Park",
    },
    {
      value: "Georgia Nick Gallery, 11 A Aviles St, St. Augustine, FL 32084",
      alias: "Georgia Nick Gallery",
    },
    {
      value: "Ginnie Springs, 7300 NE Ginnie Spgs Rd, High Springs, FL 32643",
      alias: "Ginnie Springs",
    },
    {
      value: "Green Room Brewing, 228 3rd St N, Jacksonville Beach, FL 32250",
      alias: "Green Room Brewing",
    },
    {
      value:
        "Greenwise Publix at Nocatee, 250 Pine Lake Dr, Ponte Vedra Beach, FL 32081",
      alias: "Greenwise Publix at Nocatee",
    },
    {
      value: "Jax Beach Pier, Jacksonville Beach, FL",
      alias: "Jax Beach Pier",
    },
    {
      value: "Jekyll Brewery",
      alias: "Jekyll Brewery",
    },
    {
      value: "Lemon Bar, 2 Lemon St, Neptune Beach, FL 32266",
      alias: "Lemon Bar",
    },
    {
      value: "Main Event, 10370 Philips Hwy, Jacksonville, FL 32256",
      alias: "Main Event",
    },
    {
      value: "Metropolitan Park, 64 Gator Bowl Blvd, Jacksonville, FL 32202",
      alias: "Metropolitan Park",
    },
    {
      value:
        "Mickler's Landing Beach Parking, County Rd 203, Ponte Vedra Beach, FL 32082",
      alias: "Mickler's Landing Beach Parking",
    },
    {
      value: "Myrtle Avenue Brewing, 633 Myrtle Ave N, Jacksonville, FL",
      alias: "Myrtle Avenue Brewing",
    },
    {
      value:
        "Nocatee Fields, 707 Little River Rd., Ponte Vedra Beach, FL 32081",
      alias: "Nocatee Fields",
    },
    {
      value: "Ocean City Church, 102 6th Ave N, Jacksonville Beach, FL 32250",
      alias: "Ocean City Church",
    },
    {
      value:
        "Oceanfront Park (between 5th & 6th Ave S in Jax Beach), 429 1st St S, Jacksonville Beach, FL 32250",
      alias: "Oceanfront Park (between 5th & 6th Ave S in Jax Beach)",
    },
    {
      value: "One Ocean Resort & Spa, 1 Ocean Blvd, Atlantic Beach, FL",
      alias: "One Ocean Resort & Spa",
    },
    {
      value: "Pete's Bar, 117 First St, Neptune Beach, FL 32266",
      alias: "Pete's Bar",
    },
    {
      value:
        "Publix Greenwise Market, 250 Pine Lake Dr, Ponte Vedra Beach, FL 32081",
      alias: "Publix Greenwise Market",
    },
    {
      value:
        "Recreation and Wellness, 1 UNF Dr. Bldg 61, Rm 1200, Jacksonville, FL 32224",
      alias: "Recreation and Wellness",
    },
    {
      value: "Ruby Beach Brewing, 228 E Forsyth St, Jacksonville, FL 32202",
      alias: "Ruby Beach Brewing",
    },
    {
      value: "Sadler Ranch, 869 Sadler Rd st. 2, Fernandina Beach, FL 32034",
      alias: "Sadler Ranch",
    },
    {
      value: "Sago Coffee, 318 7th Ave N, Jacksonville Beach, FL 32250",
      alias: "Sago Coffee",
    },
    {
      value: "Seawalk Pavilion, 75 1st Street, Jacksonville Beach, FL",
      alias: "Seawalk Pavilion",
    },
    {
      value:
        "Southern Swells Brewing, 1312 Beach Blvd, Jacksonville Beach, FL 32250",
      alias: "Southern Swells Brewing",
    },
    {
      value:
        "Spirit Halloween (Mandarin), 12200 San Jose Blvd #12, Jacksonville, FL 32223",
      alias: "Spirit Halloween (Mandarin)",
    },
    {
      value: "Spring Park, 229 Walnut St, Green Cove Springs, FL 32043",
      alias: "Spring Park",
    },
    {
      value:
        "St Augustine Art Association, 22 Marine Street, St Augustine, FL 32084",
      alias: "St Augustine Art Association",
    },
    {
      value:
        "St Johns County Pavilion, 350 A1A Beach Blvd, St. Augustine, FL 32080",
      alias: "St Johns County Pavilion",
    },
    {
      value:
        "St. Augustine Beach Art Studio, 370A A1A Beach Blvd, St Augustine Beach, FL 32080",
      alias: "St. Augustine Beach Art Studio",
    },
    {
      value: "Stone Climbing, 115 Strongway Ct, St. Augustine, FL 32086",
      alias: "Stone Climbing",
    },
    {
      value:
        "Sur Southside Quarter Apartments, 7385 Park Village Drive, Jacksonville, FL 32256",
      alias: "Sur Southside Quarter Apartments",
    },
    {
      value: "Surfer Bar, 200 1st St N, Jacksonville Beach, FL 32250",
      alias: "Surfer Bar",
    },
    {
      value: "The Dance Shack, 3837 Southside Blvd, Jacksonville, FL 32216",
      alias: "The Dance Shack",
    },
    {
      value:
        "The Pub on Anastasia, 213 Anastasia Blvd, St. Augustine, FL 32080",
      alias: "The Pub on Anastasia",
    },
    {
      value:
        "The Wreck Tiki Lounge, 108 3rd Ave N, Jacksonville Beach, FL 32250",
      alias: "The Wreck Tiki Lounge",
    },
    {
      value:
        "Trader Joe's Jax Beach, 4180 3rd St S, Jacksonville Beach, FL 32250",
      alias: "Trader Joe's Jax Beach",
    },
    {
      value: "UNF Driving Range, 1 Betty Holzendorf Dr, Jacksonville, FL 32224",
      alias: "UNF Driving Range",
    },
    {
      value: "UNF Lot 18, Jacksonville, FL 32224",
      alias: "UNF Lot 18",
    },
  ];

  const chatOptions = [
    { value: "Pickleball", alias: "Pickleball" },
    { value: "Volleyball", alias: "Volleyball" },
    { value: "Yoga, Runs, Workouts", alias: "Yoga, Runs, Workouts" },
    { value: "Dance", alias: "Dance" },
    { value: "Rock Climbing", alias: "Rock Climbing" },
    { value: "Other Events", alias: "Other Events" },

    // { value: "Spanish Conversation", alias: "Spanish Conversation" },
    // { value: "Golf Driving Range", alias: "Golf Driving Range" },
    // { value: "Beach Worship or Church", alias: "Beach Worship or Church" },
    // { value: "Art Walk", alias: "Art Walk" },
  ];

  useEffect(() => {
    // Fetch previous events from the API using PUT request
    const fetchPreviousEvents = async () => {
      const response = await fetch(
        "https://4cljs7mcdi.execute-api.us-east-1.amazonaws.com/default/socialevents",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            /* your PUT request payload here */
          }),
        }
      );
      const data = await response.json();
      const sortedEvents = data.socialEvents.sort((a, b) =>
        b.socialEventId.localeCompare(a.socialEventId)
      );
      setPreviousEvents(sortedEvents);
    };

    fetchPreviousEvents();
  }, []);

  const handleEventSelect = (eventId) => {
    const event = previousEvents.find((e) => e.socialEventId === eventId);
    if (event) {
      setTitle(event.title);
      setDateTime(new Date(event.date).toISOString());
      setLocation(event.location);
      setDescription(event.description);
      setLength(event.length);
      setSelectedEvent(event);
      setChat(event.chat);
      setGroupmeImg(event.groupmeImg);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here
    // split datetime into date and time
    let date = dateTime.split("T")[0];
    let time = dateTime.split("T")[1];
    // Remove seconds
    time = time.slice(0, 5);
    // Convert "18:00" (24 hour time) to "6:00 PM" (12 hour time)
    const hours = parseInt(time.split(":")[0]);
    const minutes = time.split(":")[1];
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    time = `${formattedHours}:${minutes} ${period}`;

    // Convert date from "YYYY-MM-DD" to "Month DD, YYYY"
    const [year, month, day] = date.split("-");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const formattedDate = `${monthNames[parseInt(month) - 1]} ${parseInt(
      day
    )}, ${year}`;

    const formData = {
      title,
      date: formattedDate,
      time,
      location: membersOnly ? "join group for location" : location,
      description,
      length: length === 1 ? "1hr" : length + " hrs",
      groupMeChatUrl: selectedEvent?.groupMeChatUrl,
      imgKey: selectedEvent?.imgKey,
      groupMeChatId: selectedEvent?.groupMeChatId,
      chat: selectedEvent?.chat,
      groupmeImg: selectedEvent?.groupmeImg,
      password:
        hostName || yourName || yourPhoneNumber
          ? "host: " +
            hostName +
            " - submitted by: " +
            yourName +
            " - " +
            yourPhoneNumber +
            " - " +
            password
          : password,
      generalpublic: generalPublic,
      privateLocation: location,
      host1: "1705612509309",
      host2: "none",
      pollPrivateLocation,
      pollText,
    };
    console.log(formData);

    // Send POST request
    const response = await fetch(
      "https://4cljs7mcdi.execute-api.us-east-1.amazonaws.com/default/socialevents",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (response.ok) {
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000); // Hide after 3 seconds
    } else {
      console.error("Failed to create event");
    }
  };

  const mockLocations = [
    "Jarboe Park, Neptune Beach",
    "Margaritaville, Jacksonville Beach",
    "Veterans Park, St Johns",
    "K1 Speed, Jacksonville",
    "Fort Family Park, Jacksonville",
  ];

  return (
    <div className="create-event-page">
      <header className="header">
        <button className="back-button" onClick={() => window.history.back()}>
          Back
        </button>
        <h1>Create Event</h1>
      </header>
      <main className="content">
        {showSuccessMessage ? (
          <div className="success-message">
            <span className="large-checkmark">✔</span>
            <span className="success-message-text">
              Event Created Successfully
            </span>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="event-form">
            <div className="form-item">
              <label className="custom-label">Copy from Previous Event</label>
              <select
                className="custom-select"
                onChange={(e) => handleEventSelect(e.target.value)}
              >
                {previousEvents.map((event) => (
                  <option key={event.socialEventId} value={event.socialEventId}>
                    {event.socialEventId}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-item">
              <label className="custom-label">Event Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                className="custom-input"
              />
            </div>

            <div className="form-item">
              <label className="custom-label">Date & Time</label>
              <input
                type="datetime-local"
                value={dateTime}
                onChange={(e) => setDateTime(e.target.value)}
                step="900" // 15 minutes in seconds
                className="custom-input"
              />
            </div>

            <div className="form-item">
              <label className="custom-label">Length (hours)</label>
              <input
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                required
                className="custom-input"
              />
            </div>

            <div className="form-item">
              <label className="custom-label">Location</label>
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Select or enter a location"
                className="custom-input"
              />
              <button
                className="choose-button"
                type="button"
                onClick={() => setShowLocationModal(true)}
              >
                Choose Location
              </button>
            </div>

            <div className="form-item">
              <label className="custom-label">Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={4}
                className="custom-input"
              />
            </div>

            {/* host name */}
            <div className="form-item">
              <label className="custom-label">Host Name</label>
              <span className="sub-label">(who should host this event)</span>
              <input
                type="text"
                value={hostName}
                onChange={(e) => setHostName(e.target.value)}
                className="custom-input"
              />
            </div>

            {/* your name */}
            <div className="form-item">
              <label className="custom-label">Your Full Name</label>
              <span className="sub-label">
                (*first name will only be shared if you are the host)
              </span>
              <input
                type="text"
                value={yourName}
                onChange={(e) => setYourName(e.target.value)}
                className="custom-input"
              />
            </div>

            {/* your phone number */}
            <div className="form-item">
              <label className="custom-label">Your Phone Number</label>
              <span className="sub-label">(*not shared publicly)</span>
              <input
                type="text"
                value={yourPhoneNumber}
                onChange={(e) => setYourPhoneNumber(e.target.value)}
                className="custom-input"
              />
            </div>

            <div className="form-item">
              <label className="custom-label">Host ID Number</label>
              <span className="sub-label">(enter 0 if you dont have one)</span>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="custom-input"
              />
            </div>

            <div className="form-item">
              <label className="custom-label">Public Invitation</label>
              <span className="sub-label">
                ("yes" if for entire group, "no" if for your close friends)
              </span>
              <select
                value={generalPublic}
                onChange={(e) => setGeneralPublic(e.target.value === "true")}
                className="custom-input"
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <div className="form-item">
              <label className="custom-label">Members Only</label>
              <span className="sub-label">
                ("yes" for group members only, "no" for everyone)
              </span>
              <select
                value={membersOnly}
                onChange={(e) => setMembersOnly(e.target.value === "true")}
                className="custom-input"
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <div className="form-item">
              <label className="custom-label">Short Location</label>

              <span className="sub-label">
                (short location for poll, max 3 words, not the full address)
              </span>
              <input
                type="text"
                value={pollPrivateLocation}
                onChange={(e) => setPollPrivateLocation(e.target.value)}
                className="custom-input"
              />
            </div>

            <div className="form-item">
              <label className="custom-label">Short Title</label>
              <span className="sub-label">
                (a short title for the event to use in the poll)
              </span>
              <input
                type="text"
                value={pollText}
                onChange={(e) => setPollText(e.target.value)}
                className="custom-input"
              />
            </div>

            {/* chat */}
            <div className="form-item">
              <label className="custom-label">Chat Group</label>
              <select
                value={chat}
                onChange={(e) => setChat(e.target.value)}
                className="custom-select"
              >
                <option value="">Select Chat Group</option>
                {chatOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.alias}
                  </option>
                ))}
              </select>
            </div>

            {/* groupme img */}
            <div className="form-item">
              <label className="custom-label">Event Image</label>
              <select
                value={groupmeImg}
                onChange={(e) => setGroupmeImg(e.target.value)}
                className="custom-select"
              >
                <option value="">Select Image</option>
                {imageOptions.map((option, index) => (
                  <option key={index} value={option.path}>
                    {option.alias}
                  </option>
                ))}
              </select>
            </div>

            <button type="submit" className="submit-button">
              Create Event
            </button>
          </form>
        )}

        {showLocationModal && (
          <div className="modal">
            <div className="modal-content">
              <header className="modal-header">
                <h2>Select Location</h2>
                <button onClick={() => setShowLocationModal(false)}>
                  Close
                </button>
              </header>
              <div className="modal-body">
                <input
                  type="text"
                  value={locationSearch}
                  onChange={(e) => setLocationSearch(e.target.value)}
                  placeholder="Search for a location"
                />
                <ul>
                  {locationOptions
                    .filter((loc) =>
                      loc.alias
                        .toLowerCase()
                        .includes(locationSearch.toLowerCase())
                    )
                    .map((loc, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setLocation(loc.value);
                          setShowLocationModal(false);
                        }}
                      >
                        {loc.alias}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default CreateEvent;

import React, { useContext, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";

const NavbarCustom = () => {
  // useContext for isLoggedIn
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="upperNav">
        <Container
          fluid
          className="d-flex justify-content-between align-items-center"
        >
          <div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Brand as={Link} to="/" className="text-light brandText">
            {process.env.REACT_APP_GROUP_NAME} |{" "}
            {process.env.REACT_APP_GROUP_LOCATION_NAME}
          </Navbar.Brand>

          {/* <Nav>
            {isLoggedIn ? (
              <Nav.Link as={Link} to="/logout" className="text-light">
                Hello, user!
              </Nav.Link>
            ) : (
              <>
                <Nav.Link as={Link} to="/login" className="text-light">
                  Login
                </Nav.Link>
              </>
            )}
          </Nav> */}
        </Container>

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className="text-light nav-links">
              Home
            </Nav.Link>
            {/* Other NavItems */}
            {isLoggedIn ? (
              <>
                <Nav.Link
                  as={Link}
                  to="/membership"
                  className="text-light nav-links"
                >
                  Membership
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/events"
                  className="text-light nav-links"
                >
                  Events
                </Nav.Link>
                <Nav.Link as={Link} to="/login" className="text-light">
                  Login
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/logout"
                  className="text-light nav-links"
                >
                  Logout
                </Nav.Link>
              </>
            ) : (
              <>
                {/* join group */}
                <Nav.Link as={Link} to="/join" className="text-light nav-links">
                  Join
                </Nav.Link>
                {/* nav link to events page */}
                <Nav.Link
                  as={Link}
                  to="/events"
                  className="text-light nav-links"
                >
                  Events
                </Nav.Link>
                {/* photos */}
                <Nav.Link
                  as={Link}
                  to="/photos"
                  className="text-light nav-links"
                >
                  Photos
                </Nav.Link>
                {/* nav link to subscribe page   */}
                <Nav.Link
                  as={Link}
                  to="/subscribe"
                  className="text-light nav-links"
                >
                  Subscribe
                </Nav.Link>
                {/* nav link to unsubscribe page */}
                <Nav.Link
                  as={Link}
                  to="/unsubscribe"
                  className="text-light nav-links"
                >
                  Unsubscribe
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/login"
                  className="text-light nav-links"
                >
                  Login
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavbarCustom;

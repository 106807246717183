import React, { useState, useEffect } from "react";
import "./pendingJoinRequestsUi.css";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

// Feature flag to control sign-up functionality visibility
// Set to false to hide sign-up link in production, true for testing
const ENABLE_SIGNUP = false;

// API URL - corrected to match the API Gateway structure
const API_URL =
  "https://ajje4ng1me.execute-api.us-east-1.amazonaws.com/prod/pending-join-requests";

// Cognito configuration - set these after running the Cognito setup scripts
const COGNITO_CONFIG = {
  userPoolId: "us-east-1_fc1omeTGh",
  clientId: "54fiucjkk95o6gna3l5l3dnjuo",
  region: "us-east-1",
};

// Check if Cognito is configured
const isCognitoConfigured =
  COGNITO_CONFIG.userPoolId && COGNITO_CONFIG.clientId;

// Create the Cognito User Pool object
const userPool = isCognitoConfigured
  ? new CognitoUserPool({
      UserPoolId: COGNITO_CONFIG.userPoolId,
      ClientId: COGNITO_CONFIG.clientId,
    })
  : null;

// Function to format date to "Sat Apr 4" format
const formatEventDate = (dateString) => {
  if (!dateString) return "";

  // Parse the date and ensure it's treated as UTC
  const parts = dateString.split("-");
  if (parts.length < 3) return "";

  // Create date using year, month (0-indexed), day
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JS Date
  const day = parseInt(parts[2], 10);

  // Create date object with the exact date (no time component)
  const date = new Date(Date.UTC(year, month, day));

  return date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    timeZone: "UTC", // Ensure no timezone adjustment
  });
};

export default function PendingJoinRequestsUi() {
  // State management
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cognitoPassword, setCognitoPassword] = useState("");
  const [cognitoEmail, setCognitoEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [selectedApprovals, setSelectedApprovals] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [authMode, setAuthMode] = useState(
    isCognitoConfigured ? "cognito" : "password"
  );
  const [cognitoToken, setCognitoToken] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // New state for signup form
  const [showSignup, setShowSignup] = useState(false);
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupConfirmPassword, setSignupConfirmPassword] = useState("");
  const [signupErrorMessage, setSignupErrorMessage] = useState("");
  const [signupSuccessMessage, setSignupSuccessMessage] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [needVerification, setNeedVerification] = useState(false);

  // New state for social events
  const [activeTab, setActiveTab] = useState("events");
  const [socialEvents, setSocialEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState({});
  const [daysAhead, setDaysAhead] = useState(14);
  const [calendarUpdateResults, setCalendarUpdateResults] = useState(null);
  const [expandedEvents, setExpandedEvents] = useState({});
  const [showSubscribePrompt, setShowSubscribePrompt] = useState(false);

  // Check for stored session on initial load
  useEffect(() => {
    // Check for stored token
    const storedToken = sessionStorage.getItem("cognitoIdToken");
    if (storedToken) {
      setCognitoToken(storedToken);
      setIsLoggedIn(true);

      // Parse token to get user info
      try {
        const payload = JSON.parse(atob(storedToken.split(".")[1]));
        setUserInfo({
          email: payload.email,
          sub: payload.sub,
          groups: payload["cognito:groups"] || [],
        });
        setUserRole(
          payload["cognito:groups"]?.includes("Admins")
            ? "admin"
            : payload["cognito:groups"]?.includes("Members")
            ? "member"
            : "user"
        );

        // Check expiration
        const currentTime = Math.floor(Date.now() / 1000);
        if (payload.exp && payload.exp < currentTime) {
          handleLogout();
          setErrorMessage("Your session has expired. Please login again.");
        }
      } catch (e) {
        console.error("Error parsing JWT token:", e);
      }
    }
  }, []);

  // Load data when logged in
  useEffect(() => {
    if (isLoggedIn) {
      if (activeTab === "pendingRequests" && userRole === "admin") {
        loadPendingRequests();
      } else if (activeTab === "events") {
        loadSocialEvents();
      }
    }
  }, [isLoggedIn, cognitoToken, activeTab, userRole]);

  // Direct Cognito login (no redirect)
  const handleDirectCognitoLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");

    if (!cognitoEmail || !cognitoPassword) {
      setErrorMessage("Email and password are required");
      setLoading(false);
      return;
    }

    const authenticationDetails = new AuthenticationDetails({
      Username: cognitoEmail,
      Password: cognitoPassword,
    });

    const cognitoUser = new CognitoUser({
      Username: cognitoEmail,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        // Get the ID token
        const idToken = result.getIdToken().getJwtToken();

        // Store token
        sessionStorage.setItem("cognitoIdToken", idToken);
        setCognitoToken(idToken);

        // Parse user info from token
        try {
          const payload = JSON.parse(atob(idToken.split(".")[1]));
          setUserInfo({
            email: payload.email,
            sub: payload.sub,
            groups: payload["cognito:groups"] || [],
          });
        } catch (e) {
          console.error("Error parsing JWT token:", e);
        }

        setIsLoggedIn(true);
        setLoading(false);
        // Clear password from state for security
        setCognitoPassword("");
      },
      onFailure: (err) => {
        console.error("Authentication error:", err);
        setErrorMessage(err.message || "Authentication failed");
        setLoading(false);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // Handle new password required (first login)
        setErrorMessage(
          "You need to change your password. Please contact an administrator."
        );
        setLoading(false);
      },
    });
  };

  // Password login
  const handlePasswordLogin = async (e) => {
    e.preventDefault();

    // Validation
    if (!email || !password) {
      setErrorMessage("Email and password are required");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      // Test authentication with password
      const response = await fetch(API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          password: password,
        },
      });

      if (!response.ok) {
        throw new Error("Authentication failed");
      }

      // Store password for API calls
      sessionStorage.setItem("apiPassword", password);
      sessionStorage.setItem("userEmail", email);
      setIsLoggedIn(true);
      setUserInfo({ email }); // Simple user info for password auth
    } catch (error) {
      setErrorMessage("Login failed: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Logout
  const handleLogout = () => {
    if (authMode === "cognito" && isCognitoConfigured) {
      // Clear token
      sessionStorage.removeItem("cognitoIdToken");
      setCognitoToken("");

      // With direct integration, we don't need to redirect for logout
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {
        currentUser.signOut();
      }
    } else {
      // Simple password logout
      sessionStorage.removeItem("apiPassword");
      sessionStorage.removeItem("userEmail");
    }

    setIsLoggedIn(false);
    setPendingUsers([]);
    setSocialEvents([]);
    setUserInfo(null);
    setSelectedEvents({});
    setCalendarUpdateResults(null);
  };

  // Load pending requests from API
  const loadPendingRequests = async () => {
    setLoading(true);
    setErrorMessage("");

    try {
      let headers = { "Content-Type": "application/json" };

      if (authMode === "cognito" && cognitoToken) {
        headers["Authorization"] = `Bearer ${cognitoToken}`;
      } else {
        const storedPassword = sessionStorage.getItem("apiPassword");
        if (!storedPassword) {
          setIsLoggedIn(false);
          return;
        }
        headers["password"] = storedPassword;
      }

      const response = await fetch(API_URL, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          setIsLoggedIn(false);
          throw new Error(
            "Session expired or unauthorized, please login again"
          );
        }
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setPendingUsers(data.pendingUsersWithMatches || []);
    } catch (error) {
      setErrorMessage("Failed to load pending requests: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Load social events from API
  const loadSocialEvents = async () => {
    setLoading(true);
    setErrorMessage("");
    setCalendarUpdateResults(null);

    try {
      let headers = { "Content-Type": "application/json" };

      if (authMode === "cognito" && cognitoToken) {
        headers["Authorization"] = `Bearer ${cognitoToken}`;
      } else {
        const storedPassword = sessionStorage.getItem("apiPassword");
        if (!storedPassword) {
          setIsLoggedIn(false);
          return;
        }
        headers["password"] = storedPassword;
      }

      const response = await fetch(
        `${API_URL}?type=socialEvents&days=${daysAhead}`,
        {
          method: "GET",
          headers,
        }
      );

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          setIsLoggedIn(false);
          throw new Error(
            "Session expired or unauthorized, please login again"
          );
        }
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      setSocialEvents(data.upcomingEvents || []);

      // Add formatted date for display
      data.upcomingEvents.forEach((event) => {
        const dateParts = event.socialEventId.split("-");
        if (dateParts.length >= 3) {
          const fullDate = `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
          event.shortDate = formatEventDate(fullDate);
        }
      });

      // Clear selected events that are no longer in the list
      const validEvents = {};
      data.upcomingEvents.forEach((event) => {
        if (selectedEvents[event.socialEventId]) {
          validEvents[event.socialEventId] =
            selectedEvents[event.socialEventId];
        }
      });
      setSelectedEvents(validEvents);
    } catch (error) {
      setErrorMessage("Failed to load social events: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle days ahead filter change
  const handleDaysAheadChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setDaysAhead(value);
    }
  };

  // Handle approval selection
  const handleSelectUserForApproval = (groupmeId, groupmeUserId, userId) => {
    setSelectedApprovals({
      ...selectedApprovals,
      [groupmeId]: {
        groupmeId,
        groupmeUserId,
        chosenUserId: userId,
      },
    });
  };

  // Handle event selection for calendar posting
  const handleSelectEvent = (eventId) => {
    if (selectedEvents[eventId]) {
      // If already selected, remove it
      const updatedSelections = { ...selectedEvents };
      delete updatedSelections[eventId];
      setSelectedEvents(updatedSelections);
    } else {
      // Add to selected events
      setSelectedEvents({
        ...selectedEvents,
        [eventId]: true,
      });
    }
  };

  // Handle submitting approvals
  const handleSubmitApprovals = async () => {
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      let headers = { "Content-Type": "application/json" };

      if (authMode === "cognito" && cognitoToken) {
        headers["Authorization"] = `Bearer ${cognitoToken}`;
      } else {
        const storedPassword = sessionStorage.getItem("apiPassword");
        if (!storedPassword) {
          setIsLoggedIn(false);
          return;
        }
        headers["password"] = storedPassword;
      }

      // Convert selectedApprovals object to array
      const approvalsArray = Object.values(selectedApprovals);

      if (approvalsArray.length === 0) {
        setErrorMessage("Please select users to approve");
        setLoading(false);
        return;
      }

      const response = await fetch(API_URL, {
        method: "POST",
        headers,
        body: JSON.stringify({
          approvals: approvalsArray,
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          setIsLoggedIn(false);
          throw new Error(
            "Session expired or unauthorized, please login again"
          );
        } else if (response.status === 403) {
          setErrorMessage(
            "You don't have permission to approve users. Admin access required."
          );
          setLoading(false);
          return;
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      }

      const result = await response.json();
      setSuccessMessage(
        `Successfully processed ${
          result.results.filter((r) => r.success).length
        } approvals`
      );
      setSelectedApprovals({});

      // Reload the list
      loadPendingRequests();
    } catch (error) {
      setErrorMessage("Failed to process approvals: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle submitting events to GroupMe calendar
  const handleSubmitCalendarEvents = async () => {
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    setCalendarUpdateResults(null);

    try {
      let headers = { "Content-Type": "application/json" };

      if (authMode === "cognito" && cognitoToken) {
        headers["Authorization"] = `Bearer ${cognitoToken}`;
      } else {
        const storedPassword = sessionStorage.getItem("apiPassword");
        if (!storedPassword) {
          setIsLoggedIn(false);
          return;
        }
        headers["password"] = storedPassword;
      }

      // Get array of selected event IDs
      const eventIds = Object.keys(selectedEvents);

      if (eventIds.length === 0) {
        setErrorMessage("Please select events to post to the calendar");
        setLoading(false);
        return;
      }

      const response = await fetch(API_URL, {
        method: "POST",
        headers,
        body: JSON.stringify({
          type: "calendarEvents",
          eventIds: eventIds,
        }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          setIsLoggedIn(false);
          throw new Error(
            "Session expired or unauthorized, please login again"
          );
        } else if (response.status === 403) {
          setErrorMessage(
            "You don't have permission to post events to the calendar. Admin access required."
          );
          setLoading(false);
          return;
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      }

      const result = await response.json();
      setCalendarUpdateResults(result);
      setSuccessMessage(
        `Successfully posted ${
          result.results.filter((r) => r.success).length
        } events to the GroupMe calendar`
      );

      // Clear selections
      setSelectedEvents({});

      // Reload the list to update event status
      loadSocialEvents();
    } catch (error) {
      setErrorMessage("Failed to post events to calendar: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle signup form submission
  const handleSignup = async (e) => {
    e.preventDefault();
    setSignupErrorMessage("");
    setSignupSuccessMessage("");

    // Basic validation
    if (!signupEmail || !signupPassword) {
      setSignupErrorMessage("Email and password are required");
      return;
    }

    if (signupPassword !== signupConfirmPassword) {
      setSignupErrorMessage("Passwords do not match");
      return;
    }

    setLoading(true);

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: signupEmail,
      }),
    ];

    userPool.signUp(
      signupEmail,
      signupPassword,
      attributeList,
      null,
      (err, result) => {
        setLoading(false);

        if (err) {
          setSignupErrorMessage(err.message || "Registration failed");
          return;
        }

        setSignupSuccessMessage("");
        setNeedVerification(true);
      }
    );
  };

  // Handle verification code submission
  const handleVerifyCode = (e) => {
    e.preventDefault();
    setSignupErrorMessage("");
    setSignupSuccessMessage("");

    if (!verificationCode) {
      setSignupErrorMessage("Verification code is required");
      return;
    }

    setLoading(true);

    const cognitoUser = new CognitoUser({
      Username: signupEmail,
      Pool: userPool,
    });

    cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
      setLoading(false);

      if (err) {
        setSignupErrorMessage(err.message || "Verification failed");
        return;
      }

      setSignupSuccessMessage(
        "Verification successful! You can now sign in with your credentials."
      );
      setNeedVerification(false);
      setShowSignup(false);
      setCognitoEmail(signupEmail);
    });
  };

  // Toggle between login and signup forms
  const toggleSignup = () => {
    setShowSignup(!showSignup);
    setSignupErrorMessage("");
    setSignupSuccessMessage("");
    setErrorMessage("");
    setNeedVerification(false);
  };

  // Handle toggling event card expansion
  const toggleEventExpansion = (eventId) => {
    setExpandedEvents((prev) => ({
      ...prev,
      [eventId]: !prev[eventId],
    }));
  };

  // Generate random attendance number (8-14)
  const getRandomAttendance = () => {
    return Math.floor(Math.random() * 7) + 8; // Random number between 8-14
  };

  // Handle showing subscription prompt
  const handleShowAttendees = (e) => {
    e.stopPropagation(); // Prevent collapsing the card when button is clicked
    setShowSubscribePrompt(true);
  };

  // Close subscription prompt
  const closeSubscribePrompt = () => {
    setShowSubscribePrompt(false);
  };

  // Handle subscription button click
  const handleSubscribe = () => {
    // Navigate to subscription page
    window.location.href = "/subscribe";
  };

  // Login screen
  if (!isLoggedIn) {
    return (
      <div className="login-container">
        <div className="login-box">
          <h1>{showSignup ? "Sign Up" : "Sign In"}</h1>

          {!showSignup && !needVerification ? (
            <>
              <div className="cognito-login">
                <form onSubmit={handleDirectCognitoLogin}>
                  <div className="form-group">
                    <label htmlFor="cognitoEmail">Email</label>
                    <input
                      type="email"
                      id="cognitoEmail"
                      value={cognitoEmail}
                      onChange={(e) => setCognitoEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="cognitoPassword">Password</label>
                    <input
                      type="password"
                      id="cognitoPassword"
                      value={cognitoPassword}
                      onChange={(e) => setCognitoPassword(e.target.value)}
                      placeholder="Enter password"
                      required
                    />
                  </div>
                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                  <button
                    type="submit"
                    className="login-btn"
                    disabled={loading}
                  >
                    {loading ? "Signing in..." : "Sign in"}
                  </button>
                </form>
                <div className="auth-links">
                  {ENABLE_SIGNUP && (
                    <button onClick={toggleSignup} className="switch-auth-btn">
                      Don't have an account? Sign up
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : showSignup && !needVerification ? (
            <>
              <div className="cognito-login">
                {signupSuccessMessage && (
                  <div className="success-message">{signupSuccessMessage}</div>
                )}
                {signupErrorMessage && (
                  <div className="error-message">{signupErrorMessage}</div>
                )}
                <form onSubmit={handleSignup}>
                  <div className="form-group">
                    <label htmlFor="signupEmail">Email</label>
                    <input
                      type="email"
                      id="signupEmail"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="signupPassword">Password</label>
                    <input
                      type="password"
                      id="signupPassword"
                      value={signupPassword}
                      onChange={(e) => setSignupPassword(e.target.value)}
                      placeholder="Create password"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      value={signupConfirmPassword}
                      onChange={(e) => setSignupConfirmPassword(e.target.value)}
                      placeholder="Confirm password"
                      required
                    />
                  </div>
                  <div className="password-requirements">
                    <p>Password requirements:</p>
                    <ul>
                      <li>At least 8 characters long</li>
                      <li>Contains uppercase letters</li>
                      <li>Contains lowercase letters</li>
                      <li>Contains numbers</li>
                      <li>Contains special characters</li>
                    </ul>
                  </div>
                  <button
                    type="submit"
                    className="login-btn"
                    disabled={loading}
                  >
                    {loading ? "Creating Account..." : "Create Account"}
                  </button>
                </form>
                <div className="auth-links">
                  <button onClick={toggleSignup} className="switch-auth-btn">
                    Already have an account? Sign in
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="verification-form">
              {signupSuccessMessage && (
                <div className="success-message">{signupSuccessMessage}</div>
              )}
              {signupErrorMessage && (
                <div className="error-message">{signupErrorMessage}</div>
              )}
              <p>
                Please check your email for a verification code and enter it
                below.
              </p>
              <form onSubmit={handleVerifyCode}>
                <div className="form-group">
                  <label htmlFor="verificationCode">Verification Code</label>
                  <input
                    type="text"
                    id="verificationCode"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    placeholder="Enter verification code"
                    required
                  />
                </div>
                <button type="submit" className="login-btn" disabled={loading}>
                  {loading ? "Verifying..." : "Verify Code"}
                </button>
              </form>
              <button
                onClick={() => {
                  setNeedVerification(false);
                  setShowSignup(false);
                }}
                className="switch-auth-btn"
              >
                Back to Sign In
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  // Dashboard
  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        {userInfo && (
          <span className="user-email">
            {userInfo.email} ({userRole})
          </span>
        )}
      </header>

      {userRole === "admin" && (
        <div className="admin-actions">
          <button
            className={activeTab === "pendingRequests" ? "active" : ""}
            onClick={() => setActiveTab("pendingRequests")}
          >
            Pending Join Requests
          </button>
        </div>
      )}

      <div className="dashboard-content">
        {loading && <div className="loading-spinner">Loading...</div>}

        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}

        {/* Pending Join Requests Tab - Only for admins */}
        {activeTab === "pendingRequests" && userRole === "admin" && (
          <div className="tab-content">
            <div className="actions-bar">
              <button onClick={loadPendingRequests} disabled={loading}>
                Refresh Requests
              </button>
              <button
                onClick={handleSubmitApprovals}
                disabled={
                  loading || Object.keys(selectedApprovals).length === 0
                }
                className="approve-btn"
              >
                Approve Selected ({Object.keys(selectedApprovals).length})
              </button>
            </div>

            {pendingUsers.length === 0 && !loading ? (
              <div className="no-data">No pending join requests found</div>
            ) : (
              <div className="users-list">
                {pendingUsers.map((user) => (
                  <div
                    key={user.groupmeData.id}
                    className={`user-card ${
                      user.hasConflict ? "conflict" : ""
                    }`}
                  >
                    <div className="user-header">
                      <div className="user-avatar">
                        {user.groupmeData.image_url ? (
                          <img
                            src={user.groupmeData.image_url}
                            alt={user.groupmeData.nickname}
                          />
                        ) : (
                          <div className="placeholder-avatar">
                            {user.groupmeData.nickname.charAt(0)}
                          </div>
                        )}
                      </div>
                      <div className="user-info">
                        <h3>{user.groupmeData.nickname}</h3>
                        <p>Phone: {user.groupmeData.phone}</p>
                        {user.hasConflict && (
                          <div className="conflict-badge">
                            Multiple Matches ({user.matchCount})
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="matches-container">
                      <h4>Database Matches</h4>
                      {user.matchingDbUsers.length === 0 ? (
                        <div className="no-matches">
                          No matches found in database
                        </div>
                      ) : (
                        user.matchingDbUsers.map((dbUser) => (
                          <div key={dbUser.userId} className="db-user-card">
                            <div className="db-user-details">
                              <div>
                                <strong>User ID:</strong> {dbUser.userId}
                              </div>
                              <div>
                                <strong>Name:</strong> {dbUser.name || "N/A"}
                              </div>
                              <div>
                                <strong>Phone:</strong> {dbUser.phone || "N/A"}
                              </div>
                              <div>
                                <strong>Sex:</strong> {dbUser.zsex || "N/A"}
                              </div>
                              {/* Display other relevant fields */}
                              {Object.entries(dbUser)
                                .filter(
                                  ([key, _]) =>
                                    ![
                                      "userId",
                                      "name",
                                      "phone",
                                      "zsex",
                                    ].includes(key)
                                )
                                .map(([key, value]) => (
                                  <div key={key}>
                                    <strong>{key}:</strong>{" "}
                                    {typeof value === "object"
                                      ? JSON.stringify(value)
                                      : String(value)}
                                  </div>
                                ))}
                            </div>
                            <div className="db-user-actions">
                              <button
                                onClick={() =>
                                  handleSelectUserForApproval(
                                    user.groupmeData.id,
                                    user.groupmeData.user_id,
                                    dbUser.userId
                                  )
                                }
                                className={
                                  selectedApprovals[user.groupmeData.id]
                                    ?.chosenUserId === dbUser.userId
                                    ? "selected"
                                    : ""
                                }
                              >
                                {selectedApprovals[user.groupmeData.id]
                                  ?.chosenUserId === dbUser.userId
                                  ? "Selected ✓"
                                  : "Select"}
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* Events Tab */}
        {activeTab === "events" && (
          <div className="tab-content">
            {userRole === "admin" && (
              <div className="actions-bar events-actions">
                <div className="days-filter">
                  <label htmlFor="daysAhead">Days ahead:</label>
                  <input
                    type="number"
                    id="daysAhead"
                    min="1"
                    max="30"
                    value={daysAhead}
                    onChange={handleDaysAheadChange}
                  />
                </div>
                <div className="action-buttons">
                  <button onClick={loadSocialEvents} disabled={loading}>
                    Refresh Events
                  </button>
                  <button
                    onClick={handleSubmitCalendarEvents}
                    disabled={
                      loading || Object.keys(selectedEvents).length === 0
                    }
                    className="calendar-btn"
                  >
                    Post Selected to Calendar (
                    {Object.keys(selectedEvents).length})
                  </button>
                </div>
              </div>
            )}

            {socialEvents.length === 0 && !loading ? (
              <div className="no-data">No upcoming social events found</div>
            ) : (
              <>
                <div className="events-list">
                  {socialEvents.map((event) => (
                    <div
                      key={event.socialEventId}
                      className={`event-card ${
                        event.poll && event.poll.length > 5 ? "posted" : ""
                      } ${!event.generalpublic ? "private" : ""} ${
                        expandedEvents[event.socialEventId] ? "expanded" : ""
                      }`}
                      onClick={() => toggleEventExpansion(event.socialEventId)}
                    >
                      <div className="event-header">
                        <div className="event-title">
                          <h3>{event.title}</h3>
                          <div className="event-meta">
                            <span className="event-date">{event.date}</span>
                            <span className="event-short-date">
                              {event.shortDate}
                            </span>
                            <span className="event-time">{event.time}</span>
                            {event.length && (
                              <span className="event-duration">
                                ({event.length})
                              </span>
                            )}
                            <span className="event-attendance">
                              <span className="attendance-icon">👥</span>
                              {getRandomAttendance()}
                            </span>
                          </div>
                        </div>
                        <div className="event-actions">
                          {event.poll &&
                          event.poll.length > 5 &&
                          userRole === "admin" ? (
                            <div className="event-badge posted-badge">
                              Posted to Calendar
                            </div>
                          ) : !event.generalpublic ? (
                            <div className="event-badge private-badge">
                              Private Event
                            </div>
                          ) : userRole === "admin" ? (
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent card expansion when clicking the button
                                handleSelectEvent(event.socialEventId);
                              }}
                              className={
                                selectedEvents[event.socialEventId]
                                  ? "selected"
                                  : ""
                              }
                              disabled={event.poll && event.poll.length > 5}
                            >
                              {selectedEvents[event.socialEventId]
                                ? "Selected ✓"
                                : "Select"}
                            </button>
                          ) : null}
                        </div>
                      </div>
                      {event.imgKey && (
                        <div className="event-image">
                          <img
                            src={`https://s3.amazonaws.com/social-images-public/${event.imgKey}`}
                            alt={event.title}
                            loading="lazy"
                          />
                        </div>
                      )}
                      <div className="event-details">
                        <div className="event-location">
                          <strong>Location:</strong>{" "}
                          {(userRole === "admin" || userRole === "member") &&
                          event.privateLocation
                            ? event.privateLocation
                            : event.location}
                        </div>
                        {expandedEvents[event.socialEventId] &&
                          event.description && (
                            <div className="event-description">
                              <strong>Description:</strong> {event.description}
                            </div>
                          )}
                        {expandedEvents[event.socialEventId] && (
                          <div className="see-attendees">
                            <button
                              className="see-attendees-btn"
                              onClick={handleShowAttendees}
                            >
                              See who's going
                            </button>
                          </div>
                        )}
                        {userRole === "admin" && event.chat && (
                          <div className="event-chat">
                            <strong>Chat:</strong> {event.chat}
                          </div>
                        )}
                        {userRole === "admin" && (
                          <div className="event-id">
                            <small>ID: {event.socialEventId}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {calendarUpdateResults && (
                  <div className="calendar-results">
                    <h3>Calendar Update Results</h3>
                    <div className="results-summary">
                      <p>
                        Total events processed:{" "}
                        {calendarUpdateResults.totalProcessed}
                        <br />
                        Successfully posted:{" "}
                        {calendarUpdateResults.totalSuccessful}
                      </p>
                    </div>
                    <div className="results-details">
                      {calendarUpdateResults.results.map((result, index) => (
                        <div
                          key={index}
                          className={`result-item ${
                            result.success ? "success" : "failure"
                          }`}
                        >
                          <div className="result-header">
                            <span className="result-status">
                              {result.success ? "✓ Success" : "✗ Failed"}
                            </span>
                            <span className="result-id">{result.event}</span>
                          </div>
                          {!result.success && result.message && (
                            <div className="result-message">
                              {result.message}
                            </div>
                          )}
                          {!result.success && result.error && (
                            <div className="result-error">{result.error}</div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {/* Subscription Prompt Modal */}
        {showSubscribePrompt && (
          <div className="modal-overlay" onClick={closeSubscribePrompt}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-header">
                <h3>See Who's Going</h3>
                <button className="close-button" onClick={closeSubscribePrompt}>
                  ×
                </button>
              </div>
              <div className="modal-body">
                <p>
                  To see the attendee list, you need to subscribe to our premium
                  service.
                </p>
                <p>Get access to:</p>
                <ul>
                  <li>Complete attendee lists for all events</li>
                  <li>Access to group chats for each event</li>
                </ul>
              </div>
              <div className="modal-footer">
                <button className="subscribe-btn" onClick={handleSubscribe}>
                  Subscribe Now
                </button>
                <button className="cancel-btn" onClick={closeSubscribePrompt}>
                  Maybe Later
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Chats Tab */}
        {activeTab === "chats" && (
          <div className="tab-content">
            <div className="subscription-required">
              <div className="subscription-icon">💬</div>
              <h2>Join the Conversation</h2>
              <p>Subscribe to access group chats for all events</p>
              <p className="subscription-benefits">
                With a subscription, you'll be able to:
              </p>
              <ul className="benefits-list">
                <li>Chat with other event attendees</li>
                <li>Get real-time updates from event organizers</li>
                <li>See complete lists of who's attending</li>
              </ul>
              <button className="subscribe-btn-large" onClick={handleSubscribe}>
                Subscribe Now
              </button>
            </div>
          </div>
        )}

        {/* Account Tab */}
        {activeTab === "account" && (
          <div className="tab-content">
            <div className="account-section">
              <div className="user-profile">
                <h2>Account Settings</h2>
                {userInfo && (
                  <div className="account-details">
                    <p className="account-email">
                      <strong>Email:</strong> {userInfo.email}
                    </p>
                    <p className="account-role">
                      <strong>Role:</strong> {userRole}
                    </p>
                  </div>
                )}
                <button onClick={handleLogout} className="logout-btn">
                  Logout
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Mobile App-like Bottom Navigation */}
      <div className="mobile-tab-navigation">
        <button
          className={activeTab === "events" ? "active" : ""}
          onClick={() => setActiveTab("events")}
        >
          <span className="tab-icon">📅</span>
          <span className="tab-label">Events</span>
        </button>
        <button
          className={activeTab === "chats" ? "active" : ""}
          onClick={() => setActiveTab("chats")}
        >
          <span className="tab-icon">💬</span>
          <span className="tab-label">Chats</span>
        </button>
        <button
          className={activeTab === "account" ? "active" : ""}
          onClick={() => setActiveTab("account")}
        >
          <span className="tab-icon">👤</span>
          <span className="tab-label">Account</span>
        </button>
      </div>
    </div>
  );
}

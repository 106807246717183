import React from "react";
import StripeBuyButton from "../../other/StripeBuyButton/StripeBuyButton";

const Supporter = () => {
  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center">
      <StripeBuyButton />
    </div>
  );
};

export default Supporter;
